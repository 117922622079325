import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import * as _ from "lodash";
import {
  Icon,
  IconButton,
  Input,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  callServerToSetMultipleValues,
  defineInputEnabledByOperation,
  getInputType,
  getSelectionServices,
  getCompletePathGridItem,
  findTopLeft,
  obtainPositionOfEvent,
  getDisabledSelectionServicesByDisabledInteractionProp,
  //makeErrorMapToSet,
} from "../../../../util/UtilForm";
import ContextMenu from "../../handlers/HandlerContextMenu";
import DecimalFormat from "../../../../util/DecimalFormat";
import {
  FORM_OPERATION_DELETE,
  FORM_OPERATION_VIEW,
  PADDING_ICON_BUTTONS_FORMS,
  REST_HEADERS,
  SEL_BTN_SUFFIX,
  TOKEN_HEADER,
  TOKEN_INIT,
} from "../../../../util/Constants";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { KEY_F1, KEY_TAB } from "../../../../util/UtilKeys";
import { ID_FORM_CONTAINER_ACTION_BUTTON } from "../../formContainer/FormContainer";
import { useCanChangeSaved } from "../../../../core/hooks/useCanChangeSaved";
import HelperCellInput from "./HelperCellInput";
import InteractiveCellInputButton from "./InteractiveCellInputButton";
import { useCompareSize } from "../../../../core/hooks/useCompareSize";
import { EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON } from "../../externalFormContainer/BaseForm";
import { getFormattedValueWithObjectDef } from "../../../../util/UtilFormat";
import EndTextBadge from "../../inner/EndTextBadge";

const useStyles = makeStyles((theme) => ({
  focused: {
    backgroundColor: `${theme.palette.content.mainColor}22`,
  },
}));

export default function EditableCellInput(props) {
  const {
    value: initialValue,
    row,
    column,
    operation,
    formContext,
    updateDataTable, // This is a custom function that we supplied to our table instance
    processServerResponse,
    isSettingValueInServer,
  } = props;

  const {
    formInstanceId,
    section,
    block,
    listName,
    shadowStatus,
    isBlockDisabledByWizard,
  } = formContext;
  const {
    id,
    //width,
    type,
    helperDef,
    interactiveBtnDef,
  } = column;
  const { index } = row;
  const classes = useStyles();

  const inputRef = useRef(null);
  let selectionServices = getSelectionServices(column, true);

  let completeIdControl = getCompletePathGridItem(
    section,
    block,
    `_${id}`,
    listName,
    column,
    index + 1
  );

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema: column,
    operation,
    isSavedEntity: initialValue?.isSavedEntity,
  });

  const { hoverStatus } = useCompareSize({ inputRef });

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(
    initialValue && initialValue.value ? initialValue.value : null
  );
  const [openMenu, setOpenMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: null, y: null });
  const [isEnabled, setIsEnabled] = useState(true);
  const [calculatedInputProps, setCalculatedInputProps] = useState(null);
  const inputType = getInputType(type);
  const [contextMenuPressed, setContextMenuPressed] = useState(false);
  const [lastWasTab, setLastWasTab] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  const { auth } = useContext(AuthContext);

  const REQUEST_HEADERS = {
    ...REST_HEADERS,
    [TOKEN_HEADER]: `${TOKEN_INIT}${auth.token}`,
  };

  useEffect(() => {
    if (initialValue && _.isObject(initialValue)) {
      if (!_.isNil(initialValue.value)) {
        const valueToSet = initialValue.value;
        setValue(valueToSet);
      } else {
        setValue(null);
      }
      if (!_.isNil(initialValue.presentationInfo)) {
        const { presentationInfo } = initialValue;
        if (presentationInfo && presentationInfo.reset) {
          setCalculatedInputProps(null);
        } else {
          setCalculatedInputProps(presentationInfo);
        }
      } else {
        setCalculatedInputProps(null);
      }
    }
  }, [initialValue]);

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      column?.canEdit,
      column?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }

    const applyPreConditionEnabledOperations =
      operation !== FORM_OPERATION_DELETE && operation !== FORM_OPERATION_VIEW;

    if (
      !_.isNil(calculatedInputProps) &&
      !_.isNil(calculatedInputProps.enabled) &&
      applyPreConditionEnabledOperations
    ) {
      setIsEnabled(calculatedInputProps.enabled);
    }
  }, [operation, column, calculatedInputProps]);

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    if (initialValue && initialValue.value) {
      let valueToHandle = initialValue.value;
      let formattedValue = _.clone(valueToHandle);
      if (inputType === "number") {
        // NUMBER
        if (column && column.numberFormat) {
          var df = new DecimalFormat(column.numberFormat);
          formattedValue = df.format(valueToHandle);
        }
      }
      setValue(formattedValue);
    }
  }, [initialValue, column, inputType]);

  //In charge of chaning the cell value while typing
  const onChange = (value) => {
    let formattedValue = value;
    if (inputType === "number") {
      // NUMBER
      if (column && column.numberFormat) {
        var df = new DecimalFormat(column.numberFormat);
        formattedValue = df.format(value);
      }
    }
    setValue(formattedValue);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = async (e) => {
    let mustAddNewRow = false;
    let idOfButton = null;
    let idOfTextField = null;
    let isActionButton = true;
    setInputFocused(false);
    if (e && !_.isNil(e.relatedTarget) && contextMenuPressed === false) {
      const targetAttrType =
        e.relatedTarget.getAttribute("type") ||
        e.relatedTarget.getAttribute("id");
      if (targetAttrType === "button" || targetAttrType === "checkbox") {
        e.preventDefault();
        e.stopPropagation();
        mustAddNewRow = true;
        idOfButton = e.relatedTarget.getAttribute("id");
      } else if (targetAttrType === ID_FORM_CONTAINER_ACTION_BUTTON) {
        e.preventDefault();
        e.stopPropagation();
        mustAddNewRow = true;
        idOfButton = targetAttrType;
        isActionButton = true;
      } else if (
        targetAttrType ===
        `${EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON}-${formInstanceId}`
      ) {
        e.preventDefault();
        e.stopPropagation();
        mustAddNewRow = true;
        idOfButton = targetAttrType;
        isActionButton = true;
      } else if (targetAttrType === "text" || targetAttrType === "number") {
        e.preventDefault();
        e.stopPropagation();
        idOfTextField = e.relatedTarget.getAttribute("id");
      }
      await updateDataTable(index, id, value, inputRef, column);

      if (mustAddNewRow && idOfButton && idOfButton !== "") {
        if (
          document &&
          document.getElementById(idOfButton) !== null &&
          (document.getElementById(idOfButton).getAttribute("type") ===
            "button" ||
            isActionButton === true)
        ) {
          if (lastWasTab) {
            document.getElementById(idOfButton).focus();
            setLastWasTab(false);
          } else {
            if (!isActionButton) {
              let posOfButton = obtainPositionOfEvent(idOfButton);
              setMenuPosition({
                x: posOfButton.clientX,
                y: posOfButton.clientY,
              });
            }
            document.getElementById(idOfButton).click();
          }
        }
      }
    } else {
      await updateDataTable(index, id, value, inputRef, column);
    }

    if (idOfTextField && idOfTextField !== "") {
      if (document && document.getElementById(idOfTextField) !== null) {
        document.getElementById(idOfTextField).focus();
      }
    }
  };

  //Handle selection services menu opening
  const handleOpenMenu = (event, isRightClick) => {
    if (!_.isNil(selectionServices) && !_.isEmpty(selectionServices)) {
      event.preventDefault();
      if (!isRightClick) {
        let posOfButton = obtainPositionOfEvent(completeIdControl);
        setMenuPosition({
          x: posOfButton.clientX,
          y: posOfButton.clientY,
        });
        setOpenMenu(true);
      }
    }
  };

  //Handle selection services menu closing
  const handleCloseMenu = () => {
    setOpenMenu(false);
    setMenuPosition({ x: null, y: null });
    setContextMenuPressed(false);
  };

  //Changes the value written into the state and the form data booooom
  const changeValue = async (values) => {
    const response = await callServerToSetMultipleValues(
      {
        formInstanceId,
        values,
      },
      REQUEST_HEADERS
    );

    const completeId = getCompletePathGridItem(
      section,
      block,
      `_${id}`,
      listName,
      column
    );

    processServerResponse(response, id, true, inputRef, index + 1, completeId);
  };

  const inputStyles = {
    width: "inherit",
    color:
      calculatedInputProps &&
      calculatedInputProps.color &&
      calculatedInputProps.color,
    fontSize:
      calculatedInputProps &&
      calculatedInputProps.fontSize &&
      calculatedInputProps.fontSize,
    backgroundColor:
      calculatedInputProps &&
      calculatedInputProps.backgroundColor &&
      calculatedInputProps.backgroundColor,
    fontWeight:
      calculatedInputProps &&
      calculatedInputProps.fontWeight &&
      calculatedInputProps.fontWeight,
    fontStyle:
      calculatedInputProps &&
      calculatedInputProps.fontStyle &&
      calculatedInputProps.fontStyle,
    border: !isEnabled ? "none" : "undefined",
    paddingInline: 6,
    padding:
      calculatedInputProps &&
      calculatedInputProps.padding &&
      calculatedInputProps.padding,
    paddingTop:
      calculatedInputProps &&
      calculatedInputProps.paddingTop &&
      calculatedInputProps.paddingTop,
    paddingBottom:
      calculatedInputProps &&
      calculatedInputProps.paddingBottom &&
      calculatedInputProps.paddingBottom,
    paddingLeft:
      calculatedInputProps &&
      calculatedInputProps.paddingLeft &&
      calculatedInputProps.paddingLeft,
    paddingRight:
      calculatedInputProps &&
      calculatedInputProps.paddingRight &&
      calculatedInputProps.paddingRight,
    margin:
      calculatedInputProps &&
      calculatedInputProps.margin &&
      calculatedInputProps.margin,
    marginTop:
      calculatedInputProps &&
      calculatedInputProps.marginTop &&
      calculatedInputProps.marginTop,
    marginBottom:
      calculatedInputProps &&
      calculatedInputProps.marginBottom &&
      calculatedInputProps.marginBottom,
    marginLeft:
      calculatedInputProps &&
      calculatedInputProps.marginLeft &&
      calculatedInputProps.marginLeft,
    marginRight:
      calculatedInputProps &&
      calculatedInputProps.marginRight &&
      calculatedInputProps.marginRight,
  };

  const handleTypedValue = (value) => {
    if (column && column.maxLength && value.length > column.maxLength) return;
    let formattedValue = value;
    //TEXT
    if (inputType === "text") {
      if (!_.isNil(column) && !_.isNil(column.textTransform)) {
        const { textTransform } = column;
        if (textTransform === "uppercase") {
          formattedValue = value.toUpperCase();
        } else if (textTransform === "lowercase") {
          formattedValue = value.toLowerCase();
        }
      }
    } else if (inputType === "number") {
      // NUMBER
      if (column && column.numberFormat) {
        var df = new DecimalFormat(column.numberFormat);
        formattedValue = df.format(value);
      }
    }
    onChange(formattedValue);
  };

  //Handle F1 press to Open Context Menu
  const handleKeyPress = (event) => {
    if (event.keyCode === KEY_F1 && openMenu === false) {
      event.preventDefault();
      const coords = findTopLeft(`${event?.target?.id}`);
      if (!_.isNil(coords) && !_.isNil(coords.top) && !_.isNil(coords.left)) {
        setOpenMenu(true);
        setMenuPosition({
          x: coords.left,
          y: coords.top,
        });
      }
    } else if (event.keyCode === KEY_TAB || event.which === KEY_TAB) {
      setLastWasTab(true);
    } else if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  let inputDisabled =
    !isEnabled ||
    isSettingValueInServer === true ||
    disableDueChangeSavedValue ||
    isBlockDisabledByWizard === true;

  const disabledSelectionServicesDynamic = useMemo(() => {
    return getDisabledSelectionServicesByDisabledInteractionProp({
      selectionServices,
      shadowStatus,
      line: index,
    });
  }, [index, selectionServices, shadowStatus]);

  const disabledItem = useMemo(() => {
    const selServicesSize = _.size(selectionServices);
    const disabledServicesSize = _.size(disabledSelectionServicesDynamic);

    if (selServicesSize === 1 && disabledServicesSize === 1) {
      return _.head(disabledSelectionServicesDynamic);
    } else {
      return null;
    }
  }, [disabledSelectionServicesDynamic, selectionServices]);

  const handleFocus = () => {
    setInputFocused(true);
    if (!_.isNil(inputRef) && inputRef.current && value  === '-') {
      inputRef.current.select();  
    }
  };

  return (
    <>
      {(_.isNil(helperDef) || !helperDef?.enabled) &&
        _.isNil(interactiveBtnDef) && (
          <Tooltip
            title={!_.isNil(value) ? value : ""}
            disableHoverListener={!hoverStatus}
          >
            <Input
              id={completeIdControl}
              disableUnderline={isEnabled === false}
              //inputProps={inputStyles}
              style={inputStyles}
              //value={!_.isNil(value) ? value : ""}
              value={
                !_.isNil(column?.mask) &&
                !_.isNil(value) &&
                value !== "" &&
                inputFocused === false
                  ? getFormattedValueWithObjectDef({
                      obj: column,
                      value,
                    })
                  : !_.isNil(value)
                  ? value
                  : ""
              }
              onFocus={handleFocus}
              onChange={(e) => handleTypedValue(e.target.value)}
              onBlur={(e) => onBlur(e)}
              onContextMenu={(e) => handleOpenMenu(e, true)}
              disabled={inputDisabled}
              type={
                inputType === "number" && !_.isNil(column?.mask)
                  ? "text"
                  : inputType
              }
              inputRef={inputRef}
              error={false}
              //error={errorMap.some((e) => e && e.key === completeIdControl)}
              onContextMenuCapture={() => {
                setContextMenuPressed(true);
              }}
              //onKeyDown to handling each key press for F2, F9, etc
              onKeyDown={(e) => handleKeyPress(e)}
              //Selection button
              endAdornment={
                <InputAdornment position="end">
                  <EndTextBadge schema={column} />
                  {selectionServices && selectionServices.length > 0 ? (
                    <Tooltip
                      title={disabledItem?.disableInteractionText || ""}
                      disableHoverListener={_.isNil(disabledItem)}
                    >
                      <span>
                        <IconButton
                          id={`${completeIdControl}${SEL_BTN_SUFFIX}`}
                          disabled={inputDisabled || !_.isNil(disabledItem)}
                          style={{ padding: PADDING_ICON_BUTTONS_FORMS }}
                          onClick={(e) => handleOpenMenu(e, false)}
                          tabIndex="-1"
                        >
                          <Icon className="fab fa-searchengin" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <div>{""}</div>
                  )}
                </InputAdornment>
              }
              //Turn off suggestions
              autoComplete={"off"}
              //Turn off auto correct
              autoCorrect={"off"}
              inputProps={{
                disabled: (column && column.onlySelection) || inputDisabled,
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
                style: {
                  textAlign: "inherit",
                },
              }}
              classes={{ focused: classes.focused }}
            />
          </Tooltip>
        )}

      {!_.isNil(helperDef) && helperDef?.enabled && (
        <HelperCellInput
          formInstanceId={formInstanceId}
          completeIdControl={completeIdControl}
          calculatedInputProps={calculatedInputProps}
          column={column}
          operation={operation}
          isSavedEntity={initialValue?.isSavedEntity}
          inputEnabled={!inputDisabled}
          inputValue={!_.isNil(value) ? value : ""}
          inputRef={inputRef}
          isSettingValueInServer={isSettingValueInServer}
          selectionServices={selectionServices}
          handleContextMenu={handleOpenMenu}
          handleValueTyping={handleTypedValue}
          changeValueFormContextMenu={changeValue}
          helperDef={helperDef}
          handleLostInputFocus={onBlur}
          handleKeyPress={handleKeyPress}
          line={index + 1}
          disabledSelectionServicesDynamic={disabledSelectionServicesDynamic}
        />
      )}

      {(_.isNil(helperDef) || !helperDef?.enabled) &&
        !_.isNil(interactiveBtnDef) && (
          <InteractiveCellInputButton
            formInstanceId={formInstanceId}
            completeIdControl={completeIdControl}
            calculatedInputProps={calculatedInputProps}
            column={column}
            operation={operation}
            isSavedEntity={initialValue?.isSavedEntity}
            inputEnabled={!inputDisabled}
            inputValue={!_.isNil(value) ? value : ""}
            inputRef={inputRef}
            isSettingValueInServer={isSettingValueInServer}
            selectionServices={selectionServices}
            handleContextMenu={handleOpenMenu}
            handleValueTyping={handleTypedValue}
            changeValueFormContextMenu={changeValue}
            helperDef={helperDef}
            handleLostInputFocus={onBlur}
            handleKeyPress={handleKeyPress}
            line={index + 1}
            disabledSelectionServicesDynamic={disabledSelectionServicesDynamic}
          />
        )}

      {openMenu === true && (
        <ContextMenu
          openMenu={openMenu}
          uniqueID={"selection-services-menu"}
          services={selectionServices}
          menuPosition={menuPosition}
          setValue={changeValue}
          closeMenu={handleCloseMenu}
          formContextData={formContext?.formData}
          line={index + 1}
          formInstanceId={formInstanceId}
          auto={selectionServices.length === 1}
          interactiveBtnDef={interactiveBtnDef}
          shadowStatus={shadowStatus}
          disabledSelectionServicesDynamic={disabledSelectionServicesDynamic}
        />
      )}
    </>
  );
}
