import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import * as _ from "lodash";
import {
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
  Tooltip,
  Icon,
} from "@material-ui/core";
import {
  Visibility,
  VisibilityOff,
  //Lock, LockOpen
} from "@material-ui/icons";

import {
  getInputType,
  defineInputEnabledByOperation,
  getCompletePath,
  returnFocusToRef,
} from "../../../util/UtilForm";
import {
  getTransformedTextByDef,
  isMaxLengthReached,
  validatePasswordStrength,
} from "../../../util/UtilFormat";
import {
  FORM_INPUT_TEXT,
  FORM_OPERATION_DELETE,
  FORM_OPERATION_VIEW,
} from "../../../util/Constants";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
import { useTranslation } from "react-i18next";
import { ID_FORM_CONTAINER_ACTION_BUTTON } from "../formContainer/FormContainer";
import { EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON } from "../externalFormContainer/BaseForm";
import {
  checkTypeOfElementById,
  ELEMENT_TYPE_BTN,
  ELEMENT_TYPE_TEXT,
} from "../../../util/util-io";

const iconError = {
  width: "auto",
  height: "auto",
  color: "orangered",
  fontSize: "16px",
  cursor: "pointer",
};

export default function SimplePasswordInputField(props) {
  //#region [Definitions]
  const { t } = useTranslation();
  const { idSchema, schema, formData, uiSchema, formContext, name } = props;
  //Destr important attrs
  const {
    value: inputValue,
    presentationInfo: pi,
    isSavedEntity,
  } = formData || {};
  const theme = useTheme();
  const inputRef = useRef(null);
  const [inputEnabled, setInputEnabled] = useState(true);
  const [presentationInfo, setPresentationInfo] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  //const [manualEdit, setManualEdit] = useState(false);
  const [lastWasTab, setLastWasTab] = useState(false);

  const {
    operation,
    section,
    block,
    formInstanceId,
    handleFormDataChange,
    globalFormData,
    notifySetValueToServer,
    pre,
    isBlockDisabledByWizard,
    shadowStatus
  } = formContext;
  //#endregion

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema,
    operation,
    isSavedEntity,
  });

  const isPasswordStrong = useMemo(() => {
    return validatePasswordStrength(inputValue);
  }, [inputValue]);

  const conditionShowErrorByPasswordStrongness =
    !_.isNil(inputValue) &&
    _.size(inputValue) > 0 &&
    isPasswordStrong === false;

  //IMPORTANT check if input es enabled by operation and props canEdit and canNew
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      schema?.canEdit,
      schema?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setInputEnabled(checkInputEnabled);
    }
  }, [operation, schema]);

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  //In charge of SET VALUE IN SERVER AFTER INPUT FOCUS IS LOST
  const handleLostInputFocus = async (e) => {
    let mustAddNewRow = false;
    let idOfButton = null;
    let idOfTextField = null;
    let isActionButton = true;
    const fieldValue = inputValue && inputValue !== "" ? inputValue : null;

    if (conditionShowErrorByPasswordStrongness) {
      // clear field
      handleValueTyping("");
    } else {
      const hasRelatedTarget = !_.isNil(e.relatedTarget);
      if (hasRelatedTarget) {
        const relatedTarget = e.relatedTarget;

        const targetAttrType =
          relatedTarget.getAttribute("type") ||
          relatedTarget.getAttribute("id");

        if (targetAttrType === "button" || targetAttrType === "checkbox") {
          e.preventDefault();
          e.stopPropagation();
          mustAddNewRow = true;
          idOfButton = relatedTarget.getAttribute("id");
        } else if (targetAttrType === ID_FORM_CONTAINER_ACTION_BUTTON) {
          e.preventDefault();
          e.stopPropagation();
          mustAddNewRow = true;
          idOfButton = targetAttrType;
          isActionButton = true;
        } else if (
          targetAttrType ===
          `${EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON}-${formInstanceId}`
        ) {
          e.preventDefault();
          e.stopPropagation();
          mustAddNewRow = true;
          idOfButton = targetAttrType;
          isActionButton = true;
        } else if (targetAttrType === "text") {
          e.preventDefault();
          e.stopPropagation();
          idOfTextField = relatedTarget.getAttribute("id");
        }

        let returnFocusToInput = false;
        if (!_.isNil(returnFocusToInput) && returnFocusToInput === true) {
          returnFocusToRef(inputRef);
        } else {
          await notifySetValueToServer(
            formInstanceId,
            completeIdControl,
            fieldValue,
            0,
            inputRef
          );

          if (
            checkTypeOfElementById(idOfTextField, ELEMENT_TYPE_TEXT) === true
          ) {
            document.getElementById(idOfTextField).focus();
          }
          if (
            mustAddNewRow &&
            (checkTypeOfElementById(idOfButton, ELEMENT_TYPE_BTN) === true ||
              isActionButton)
          ) {
            if (
              lastWasTab &&
              idOfButton !== `${completeIdControl}_toggle_btn`
            ) {
              document.getElementById(idOfButton).focus();
              setLastWasTab(false);
            } else {
              if (
                document &&
                document.getElementById(idOfButton) &&
                idOfButton !== `${completeIdControl}_toggle_btn`
              ) {
                document.getElementById(idOfButton).click();
              }
            }
          }
        }
      } else {
        await notifySetValueToServer(
          formInstanceId,
          completeIdControl,
          fieldValue,
          0,
          inputRef
        );
      }
    }
  };

  //Handle values when data is typed in input --> SET FORM DATA
  const handleValueTyping = (value) => {
    let typedValue = _.clone(value);
    if (isMaxLengthReached(typedValue, schema)) return;
    const inputType = getInputType(schema?.type);
    if (inputType && inputType === FORM_INPUT_TEXT) {
      typedValue = getTransformedTextByDef(typedValue, schema);
    }

    //VERY IMPORTANTE LINE, form context has a bug in development/debug mode
    const prevoiusStateToSet = globalFormData;

    if (!_.isNil(typedValue) && typedValue !== "") {
      //Making new object with name as key and typed value as value to set
      let obj = { value: typedValue, presentationInfo };

      const accessor =
        schema && schema.entityParent ? `${schema.entityParent}.${name}` : name;

      //Set new form data through form context
      const newFormData = _.clone(prevoiusStateToSet);
      _.set(newFormData, accessor, obj);

      //Setting GLOBAL form data
      handleFormDataChange(newFormData);
    } else if (!_.isNil(typedValue) && typedValue === "") {
      //Check if prev state is valid
      if (!_.isNil(prevoiusStateToSet)) {
        const fieldControl = {
          ...prevoiusStateToSet[name],
          value: typedValue,
        };
        const newFormData = { ...prevoiusStateToSet, [name]: fieldControl };

        //Setting GLOBAL form data
        handleFormDataChange(newFormData);
      }
    }
  };

  //Ui Schema styles
  const uiSchemaStyles =
    !_.isNil(uiSchema) && !_.isNil(uiSchema.styles)
      ? JSON.parse(uiSchema?.styles)
      : null;

  //Input styles
  const inputStyles = {
    ...uiSchemaStyles,
    fontFamily: theme.palette.form.inputFontFamily,
    textTransform:
      !_.isNil(schema) && !_.isNil(schema.textTransform)
        ? schema.textTransform
        : "none",
    color: presentationInfo && presentationInfo.color && presentationInfo.color,
    fontSize:
      presentationInfo &&
      presentationInfo.fontSize &&
      presentationInfo.fontSize,
    backgroundColor:
      presentationInfo && presentationInfo.backgroundColor
        ? presentationInfo.backgroundColor
        : "inherit",
    fontWeight:
      presentationInfo &&
      presentationInfo.fontWeight &&
      presentationInfo.fontWeight,
    fontStyle:
      presentationInfo &&
      presentationInfo.fontStyle &&
      presentationInfo.fontStyle,
    width: "100%",
    "&:disabled": {
      backgroundColor: "red",
    },
  };

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      presentationInfo={presentationInfo}
      shadowStatus={shadowStatus}
    >
      <div style={{ width: "100%" }}>
        <TextField
          //generated id for better performance and avoid multiple input with same id
          id={completeIdControl}
          //enabled or not by operation, canEdit, canNew
          disabled={
            inputEnabled === false ||
            schema?.readOnly === true ||
            disableDueChangeSavedValue ||
            isBlockDisabledByWizard === true
          }
          //custom styles for the input if it has
          style={inputStyles}
          //If it is visible or not
          type={showPassword ? "text" : "password"}
          //value={value || ""}
          value={inputValue || ""}
          //onChange to handling change value of the input
          onChange={(evt) => handleValueTyping(evt.target.value)}
          //OnBlur to handling the lost of focus
          onBlur={(e) => {
            handleLostInputFocus(e);
          }}
          //Placeholder to show in input
          placeholder={!_.isNil(schema.placeHolder) ? schema.placeHolder : ""}
          //Max length for the input
          maxLength={!_.isNil(schema.maxLength) ? schema.maxLength : -1}
          //ref of input
          inputRef={inputRef}
          // label={schema?.title}
          error={conditionShowErrorByPasswordStrongness}
          //error={errorMap.some((e) => e && e.key === completeIdControl)}
          InputProps={{
            //disabled: manualEdit === false,
            startAdornment: conditionShowErrorByPasswordStrongness ? (
              <InputAdornment position="start">
                <Tooltip title={t("CHANGE_PASS_DIALOG_VALIDATE_PASS_STRENGTH")}>
                  <Icon
                    className="fas fa-exclamation-circle"
                    fontSize="small"
                    style={iconError}
                  />
                </Tooltip>
              </InputAdornment>
            ) : null,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id={`${completeIdControl}_toggle_btn`}
                  disabled={
                    operation === FORM_OPERATION_DELETE ||
                    operation === FORM_OPERATION_VIEW
                    //|| manualEdit === false
                  }
                  aria-label="toogle pass visibility"
                  onClick={handleShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                {/* <IconButton
                  id={`${completeIdControl}_lock_btn`}
                  disabled={
                    operation === FORM_OPERATION_DELETE ||
                    operation === FORM_OPERATION_VIEW ||
                    disableDueChangeSavedValue
                  }
                  aria-label="toogle pass lock"
                  onClick={() => {
                    handleValueTyping("");
                    setManualEdit(!manualEdit);
                  }}
                >
                  {manualEdit ? <LockOpen /> : <Lock />}
                </IconButton> */}
              </InputAdornment>
            ),
          }}
        />
      </div>
    </SimpleFieldContainer>
  );
}
