import React, { useMemo, useState, useEffect } from "react";
import { Icon, Input, makeStyles, Tooltip, useTheme } from "@material-ui/core";
import _ from "lodash";

import { useCanChangeSaved } from "../../../../core/hooks/useCanChangeSaved";
import { getInputType } from "../../../../util/UtilForm";
import SelectionAdornment from "./SelectionAdornment";
import { useCompareSize } from "../../../../core/hooks/useCompareSize";
import {
  getFormattedValueWithObjectDef,
  isMaxLengthReached,
} from "../../../../util/UtilFormat";
import EndTextBadge from "../../inner/EndTextBadge";

const iconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  transform: "scale(0.7)",
};

const useStyles = makeStyles((theme) => ({
  focused: {
    backgroundColor: `${theme.palette.content.mainColor}22`,
  },
}));

const determineValue = ({ inputValue }) => {
  if (inputValue === null || inputValue === undefined) {
    return "";
  }

  return inputValue;
};

export default function NormalInput(props) {
  const {
    completeIdControl,
    presentationInfo,
    schema,
    uiSchema,
    operation,
    isSavedEntity,
    inputEnabled,
    inputValue,
    inputRef,
    isSettingValueInServer,
    selectionServices,
    handleContextMenu,
    handleKeyPress,
    hanleInputFocus,
    handleLostInputFocus,
    handleValueTyping,
    disabledSelectionServicesDynamic,
  } = props;

  const theme = useTheme();
  const classes = useStyles();

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema,
    operation,
    isSavedEntity,
  });

  const { hoverStatus } = useCompareSize({ inputRef });
  const [inputFocused, setInputFocused] = useState(false);
  const [localValue, setLocalValue] = useState(determineValue({ inputValue }));

  useEffect(() => {
    const valueDetermined = determineValue({ inputValue });
    setLocalValue(valueDetermined);
  }, [inputValue]);

  const handleChange = (evt) => {
    const newValue = evt.target.value;
    if (isMaxLengthReached(newValue, schema)) return;
    setLocalValue(newValue);
  };

  const handleBlur = async (e) => {
    setInputFocused(false);
    const valueToSend = localValue;
    if (localValue !== inputValue) {
      await handleValueTyping(valueToSend);
    }
    await handleLostInputFocus(e, valueToSend);
  };

  const handleFocus = (e) => {
    setInputFocused(true);
    if (hanleInputFocus) {
      hanleInputFocus(e);
    }
    if (!_.isNil(inputRef) && inputRef.current && inputValue === "-") {
      inputRef.current.select();
    }
  };

  //Ui Schema styles
  const uiSchemaStyles =
    !_.isNil(uiSchema) && !_.isNil(uiSchema.styles)
      ? JSON.parse(uiSchema?.styles)
      : null;

  const brd = useMemo(() => {
    return presentationInfo?.borderRadius || 0;
  }, [presentationInfo]);

  //Input styles
  const inputStyles = {
    ...uiSchemaStyles,
    fontFamily: theme.palette.form.inputFontFamily,
    textTransform:
      !_.isNil(schema) && !_.isNil(schema.textTransform)
        ? schema.textTransform
        : "none",
    color: presentationInfo && presentationInfo.color && presentationInfo.color,
    fontSize:
      presentationInfo &&
      presentationInfo.fontSize &&
      presentationInfo.fontSize,
    backgroundColor:
      presentationInfo &&
      presentationInfo.backgroundColor &&
      presentationInfo.backgroundColor,
    //: "inherit",
    fontWeight:
      presentationInfo &&
      presentationInfo.fontWeight &&
      presentationInfo.fontWeight,
    fontStyle:
      presentationInfo &&
      presentationInfo.fontStyle &&
      presentationInfo.fontStyle,
    width: "100%",
    borderRadius: brd,
    padding:
      presentationInfo && presentationInfo.padding && presentationInfo.padding,
    paddingTop:
      presentationInfo &&
      presentationInfo.paddingTop &&
      presentationInfo.paddingTop,
    paddingBottom:
      presentationInfo &&
      presentationInfo.paddingBottom &&
      presentationInfo.paddingBottom,
    paddingLeft:
      presentationInfo &&
      presentationInfo.paddingLeft &&
      presentationInfo.paddingLeft,
    paddingRight:
      presentationInfo &&
      presentationInfo.paddingRight &&
      presentationInfo.paddingRight,
    margin:
      presentationInfo && presentationInfo.margin && presentationInfo.margin,
    marginTop:
      presentationInfo &&
      presentationInfo.marginTop &&
      presentationInfo.marginTop,
    marginBottom:
      presentationInfo &&
      presentationInfo.marginBottom &&
      presentationInfo.marginBottom,
    marginLeft:
      presentationInfo &&
      presentationInfo.marginLeft &&
      presentationInfo.marginLeft,
    marginRight:
      presentationInfo &&
      presentationInfo.marginRight &&
      presentationInfo.marginRight,
  };

  const inputTextAlign = schema?.textAlign || "left";
  const multiline = schema?.multiline;

  const diabledTopLevel =
    inputEnabled === false ||
    (presentationInfo && presentationInfo?.enabled === false) ||
    disableDueChangeSavedValue;

  const disabledSubLevel =
    inputEnabled === false ||
    isSettingValueInServer === true ||
    (schema && schema.onlySelection) ||
    (presentationInfo && presentationInfo?.enabled === false) ||
    disableDueChangeSavedValue;

  const iconStylesPre = useMemo(() => {
    const actualTheme = theme?.palette?.type || "light";

    const ic =
      !_.isNil(presentationInfo?.iconColor) &&
      _.isObject(presentationInfo?.iconColor) &&
      !_.isEmpty(presentationInfo?.iconColor) &&
      _.has(presentationInfo?.iconColor, actualTheme)
        ? _.get(presentationInfo?.iconColor, actualTheme)
        : null;

    return ic
      ? {
          ...iconStyles,
          color: ic,
        }
      : iconStyles;
  }, [presentationInfo, theme]);

  const innerInputStyle =
    multiline && multiline > 0
      ? {
          textAlign: inputTextAlign,
          borderRadius: brd,
        }
      : {
          textAlign: inputTextAlign,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          borderRadius: brd,
        };

  return (
    <Tooltip
      title={
        !_.isNil(localValue) && (diabledTopLevel || disabledSubLevel)
          ? localValue
          : ""
      }
      disableHoverListener={!hoverStatus}
    >
      <Input
        //generated id for better performance and avoid multiple input with same id
        id={completeIdControl}
        //enabled or not by operation, canEdit, canNew
        disabled={diabledTopLevel}
        //custom styles for the input if it has
        style={inputStyles}
        classes={{ focused: classes.focused }}
        //If it is text or number
        type={
          getInputType(schema?.type) === "number" && !_.isNil(schema?.mask)
            ? "text"
            : getInputType(schema?.type)
        }
        value={
          !_.isNil(schema?.mask) &&
          !_.isNil(localValue) &&
          localValue !== "" &&
          inputFocused === false
            ? getFormattedValueWithObjectDef({ obj: schema, value: localValue })
            : localValue
        }
        //onChange to handling change value of the input
        onChange={handleChange}
        //onContextMenu to handling right click on the input to display the context menu items
        onContextMenu={(e) => handleContextMenu(e, true)}
        //onKeyDown to handling each key press for F2, F9, etc
        onKeyDown={(e) => handleKeyPress(e)}
        //OnBlur to handling the lost of focus
        onBlur={handleBlur}
        //OnFOcus
        onFocus={handleFocus}
        //Placeholder to show in input
        placeholder={!_.isNil(schema.placeHolder) ? schema.placeHolder : ""}
        //Max length for the input
        maxLength={!_.isNil(schema.maxLength) ? schema.maxLength : -1}
        //ref of input
        inputRef={inputRef}
        // label={schema?.title}
        error={false}
        //error={errorMap.some((e) => e && e.key === completeIdControl)}
        //Multilne
        multiline={multiline && multiline > 0 ? true : false}
        //Rows
        minRows={multiline ? Number.parseInt(multiline) : 1}
        //Max Rows
        maxRows={multiline ? Number.parseInt(multiline) : 1}
        //Button
        endAdornment={
          <>
            <EndTextBadge schema={schema} />
            <SelectionAdornment
              selectionServices={selectionServices}
              completeIdControl={completeIdControl}
              isSettingValueInServer={isSettingValueInServer}
              presentationInfo={presentationInfo}
              disableDueChangeSavedValue={disableDueChangeSavedValue}
              handleContextMenu={handleContextMenu}
              inputEnabled={inputEnabled}
              hasHelper={false}
              disabledSelectionServicesDynamic={
                disabledSelectionServicesDynamic
              }
            />
          </>
        }
        startAdornment={
          <>
            {!_.isNil(presentationInfo?.icon) ? (
              <Icon className={presentationInfo?.icon} style={iconStylesPre} />
            ) : null}
          </>
        }
        //Turn off suggestions
        autoComplete={"off"}
        //Turn off auto correct
        autoCorrect={"off"}
        inputProps={{
          style: innerInputStyle,
          disabled: disabledSubLevel,
          autoComplete: "off",
          form: {
            autoComplete: "off",
          },
        }}
        disableUnderline={
          !_.isNil(presentationInfo?.disableUnderline) &&
          presentationInfo?.disableUnderline === true
        }
      />
    </Tooltip>
  );
}
