import React, { useState, useMemo, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Icon,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";

import { useStyles, commonIconStyles } from "./ChangePassDialogStyles";
import { validatePasswordStrength } from "../../../util/UtilFormat";
import { AuthContext } from "../../../core/providers/AuthContext";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { equals } from "lodash/fp";
import { toLower } from "lodash";

function DraggablePaperComponent(props) {
  return (
    <Draggable handle="#change-pass-title">
      <Paper {...props} />
    </Draggable>
  );
}

export default function ChangePassDialog({ open, handleChangePass }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { auth, logout } = useContext(AuthContext);
  const { mustChangePass } = auth;

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  function handleAction(close) {
    if (close) {
      handleChangePass({ close });
    } else {
      handleChangePass({ close, oldP: oldPass, newP: newPass });
    }
  }

  function passNotMatch() {
    return newPass !== "" && confirmPass !== "" && newPass !== confirmPass;
  }

  const passAreEquals = useMemo(() => {
    return (
      oldPass !== "" &&
      newPass !== "" &&
      equals(toLower(oldPass), toLower(newPass))
    );
  }, [newPass, oldPass]);

  const isPasswordStrong = useMemo(() => {
    return validatePasswordStrength(newPass);
  }, [newPass]);

  function handleCancelButton() {
    if (mustChangePass) {
      logout();
    } else {
      handleAction(true);
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="change-pass-dialog"
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          minWidth: 300,
        },
      }}
      PaperComponent={DraggablePaperComponent}
    >
      <DialogTitle id="change-pass-title" style={{ cursor: "move" }}>
        <div className={classes.dialogTitleContent}>
          <Icon className="fas fa-key" style={{ ...commonIconStyles }} />
          {t("CHANGE_PASS_DIALOG_TITLE")}
        </div>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <TextField
          label={t("CHANGE_PASS_DIALOG_OLD_PASS_LABEL")}
          variant="outlined"
          type={showOldPass ? "text" : "password"}
          value={oldPass}
          onChange={(e) => setOldPass(e.target.value)}
          onBlur={() => setShowOldPass(false)}
          onFocus={() => {
            setShowNewPass(false);
            setShowConfirmPass(false);
          }}
          className={classes.dialogInput}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
          }}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle old password visibility"
                  onClick={() => setShowOldPass((prev) => !prev)}
                  edge="end"
                >
                  {showOldPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={t("CHANGE_PASS_DIALOG_NEW_PASS_LABEL")}
          variant="outlined"
          type={showNewPass ? "text" : "password"}
          value={newPass}
          onChange={(e) => setNewPass(e.target.value)}
          onBlur={() => setShowNewPass(false)}
          onFocus={() => {
            setShowOldPass(false);
            setShowConfirmPass(false);
          }}
          className={classes.dialogInput}
          error={!isPasswordStrong}
          helperText={
            isPasswordStrong
              ? ""
              : t("CHANGE_PASS_DIALOG_VALIDATE_PASS_STRENGTH")
          }
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
          }}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle new password visibility"
                  onClick={() => setShowNewPass((prev) => !prev)}
                  edge="end"
                >
                  {showNewPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={t("CHANGE_PASS_DIALOG_NEW_PASS_CONF_LABEL")}
          variant="outlined"
          type={showConfirmPass ? "text" : "password"}
          value={confirmPass}
          onChange={(e) => setConfirmPass(e.target.value)}
          onBlur={() => setShowConfirmPass(false)}
          onFocus={() => {
            setShowOldPass(false);
            setShowNewPass(false);
          }}
          className={classes.dialogInput}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
          }}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={() => setShowConfirmPass((prev) => !prev)}
                  edge="end"
                >
                  {showConfirmPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {passNotMatch() === true && (
          <div className={classes.passNotMatch}>
            * {t("CHANGE_PASS_DIALOG_NEW_PASS_CONF_INCORRECT")}
          </div>
        )}
        {passAreEquals === true && (
          <div className={`${classes.passNotMatch} ${classes.dialogInput}`}>
            * {t("CHANGE_PASS_DIALOG_CAN_NOT_BE_THE_SAME")}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            oldPass === "" ||
            newPass === "" ||
            confirmPass === "" ||
            passNotMatch() === true ||
            isPasswordStrong === false ||
            passAreEquals === true
          }
          onClick={() => handleAction(false)}
        >
          {t("FORM_SAVE")}
        </Button>
        <Button onClick={handleCancelButton}>{t("FORM_CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
