import React, { useState, useEffect, useRef, useMemo } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import * as _ from "lodash";
import { InputLabel } from "@material-ui/core";

import {
  callServerToSetMultipleValues,
  defineInputEnabledByOperation,
  extractErrorMessageFromResponse,
  formatValuesMultiComboBox,
  getCompletePath,
  isServerResponseValid,
  makeEmptyMultiComboValue,
  makeValuesToSetValuesMultiple,
  resolveFieldAgainstShadowStatus,
  transformFormStatusFromServer,
} from "../../../util/UtilForm";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { TOAST_TYPE_ERROR } from "../../../util/Constants";
import { useBlockingToast } from "../../../core/hooks/useBlockingToast";

export default function SimpleMultiComboBoxField(props) {
  const { idSchema, schema, formData, formContext } = props;
  const {
    value: comboValue,
    optionsValues,
    presentationInfo: pi,
    isSavedEntity,
  } = formData || {};
  const {
    operation,
    section,
    block,
    formInstanceId,
    handleFormDataChange,
    globalFormData,
    pre,
    shadowStatus,
    updateShadowStatus,
    isBlockDisabledByWizard,
  } = formContext;
  const [isEnabled, setIsEnabled] = useState(true);
  const comboRef = useRef(null);
  const [comboState, setComboState] = useState("");
  const [presentationInfo, setPresentationInfo] = useState(null);

  const REQUEST_HEADERS = useRequestHeaders();

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  const multiComboProps = schema?.multiComboProps;

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema,
    operation,
    isSavedEntity,
  });

  const { renderBlockingToast, BlockingToastModal } = useBlockingToast();

  const availableOptions = useMemo(() => {
    if (_.isNil(multiComboProps) || _.isNil(multiComboProps?.display)) {
      return [];
    }

    if (
      !_.isNil(optionsValues) &&
      !_.isEmpty(optionsValues) &&
      _.isArray(optionsValues)
    ) {
      const display = multiComboProps?.display;
      return optionsValues.map((o) => o[display]);
    }

    if (
      (_.isNil(optionsValues) || _.isEmpty(optionsValues)) &&
      !_.isNil(comboState) &&
      comboState !== ""
    ) {
      return [comboState];
    }

    return [];
  }, [multiComboProps, optionsValues, comboState]);

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      schema?.canEdit,
      schema?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }
    if (_.isNil(availableOptions) || _.isEmpty(availableOptions)) {
      setIsEnabled(false);
    }
  }, [operation, schema, availableOptions]);

  useEffect(() => {
    if (comboValue === null) {
      setComboState("");
    } else {
      setComboState(comboValue);
    }
  }, [comboValue, formData]);

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  const handleChange = async (value) => {
    setComboState(value);

    const fieldsToReturn = multiComboProps?.valsToSet;
    const display = multiComboProps?.display;
    const objectSelected = !_.isEmpty(value)
      ? _.find(optionsValues, { [display]: value })
      : makeEmptyMultiComboValue({ optionsValues });

    if (!_.isNil(fieldsToReturn) && !_.isNil(objectSelected)) {
      let valuesMade = makeValuesToSetValuesMultiple(
        fieldsToReturn,
        [objectSelected],
        0
      );

      const valuesToSet = formatValuesMultiComboBox(
        valuesMade,
        completeIdControl
      );

      //Call service to set multiple values
      const response = await callServerToSetMultipleValues(
        {
          formInstanceId,
          values: valuesToSet,
        },
        REQUEST_HEADERS
      );

      //Analyze response
      if (isServerResponseValid(response)) {
        const { fstatus } = response;
        const transformedFormStatus = transformFormStatusFromServer(fstatus);
        const newGlobalData = { ...globalFormData, ...transformedFormStatus };

        //Set new form data through form context
        await handleFormDataChange(newGlobalData);
        if (!_.isNil(updateShadowStatus) && _.isFunction(updateShadowStatus)) {
          updateShadowStatus(fstatus);
        }
      } else if (
        !_.isNil(response) &&
        !_.isNil(response.error) &&
        response.error === true &&
        !_.isNil(response.msg)
      ) {
        //Extract error message if there is one
        const toastText = extractErrorMessageFromResponse(response);

        //Throw toast
        // toast.error(toastText, {
        //   containerId: TOAST_CONTAINER_FORM_CONTAINER,
        //   toastId: completeIdControl,
        //   position: "top-right",
        //   autoClose: false,
        //   hideProgressBar: false,
        //   closeOnClick: false,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });

        renderBlockingToast({
          type: TOAST_TYPE_ERROR,
          title: toastText,
        });
      }
    }
  };

  //Input styles
  const controlStyles = {
    width: "100%",
    color: presentationInfo && presentationInfo.color && presentationInfo.color,
    fontSize:
      presentationInfo &&
      presentationInfo.fontSize &&
      presentationInfo.fontSize,
    backgroundColor:
      presentationInfo &&
      presentationInfo.backgroundColor &&
      presentationInfo.backgroundColor,
    fontWeight:
      presentationInfo &&
      presentationInfo.fontWeight &&
      presentationInfo.fontWeight,
    fontStyle:
      presentationInfo &&
      presentationInfo.fontStyle &&
      presentationInfo.fontStyle,
  };

  const isClearabale = useMemo(() => {
    if (
      _.isNil(schema) ||
      _.isNil(comboValue) ||
      _.isNil(schema?.isClearable) ||
      _.trim(_.toString(comboValue)) === ""
    ) {
      return true;
    }

    const definedIsClearable = schema?.isClearable;
    if (_.isBoolean(definedIsClearable)) {
      return definedIsClearable;
    } else if (_.isString(definedIsClearable)) {
      const valueInShadowStatus = resolveFieldAgainstShadowStatus({
        field: definedIsClearable,
        shadowStatus,
        completeIdControl: definedIsClearable,
      });
      return (
        _.isEqual("S", _.toUpper(_.toString(valueInShadowStatus))) ||
        _.isEqual("TRUE", _.toUpper(_.toString(valueInShadowStatus))) ||
        _.isEqual("Y", _.toUpper(_.toString(valueInShadowStatus)))
      );
    } else {
      return true;
    }
  }, [schema, comboValue, shadowStatus]);

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      presentationInfo={presentationInfo}
      shadowStatus={shadowStatus}
    >
      <BlockingToastModal />
      <InputLabel htmlFor={completeIdControl} />
      <NativeSelect
        value={comboState}
        onChange={(e) => handleChange(e.target.value)}
        disabled={
          !isEnabled ||
          (presentationInfo && presentationInfo?.enabled === false) ||
          disableDueChangeSavedValue ||
          isBlockDisabledByWizard === true
        }
        style={controlStyles}
        inputRef={comboRef}
        //onBlur={(e) => handleChange(comboState)}
        id={completeIdControl}
        error={false}
        inputProps={{
          id: completeIdControl,
        }}
        //error={errorMap.some((e) => e && e.key === completeIdControl)}
      >
        {isClearabale && <option aria-label="None" value="" />}
        {!_.isNil(availableOptions) &&
          !_.isEmpty(availableOptions) &&
          availableOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
      </NativeSelect>
    </SimpleFieldContainer>
  );
}
