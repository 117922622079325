import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

//Importing main Router
import Router from "../routes/Router";
import RootContextProvider from "../core/providers/RootContext";

//Import common styles
import "../shared/styles/common/CommonStyles.css";
import FontAwesomeCSS from "../shared/styles/fontAwesome/FontAwesomeCSS";

import ErrorFallback from "./ErrorFallback";
import useEnvironmentStore from "../core/stores/EnvironmentStore";
import MaintenanceFallback from "./MaintenanceFallback";

export default function App() {
  const { initializeConfig, showMaintenanceDialog, maintenanceDialogETA } =
    useEnvironmentStore();

  useEffect(() => {
    initializeConfig();
  }, [initializeConfig]);

  if (showMaintenanceDialog === true) {
    return <MaintenanceFallback eta={maintenanceDialogETA} />;
  }

  return (
    <RootContextProvider>
      <FontAwesomeCSS />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Router />
      </ErrorBoundary>
    </RootContextProvider>
  );
}
