export class StringBuilder {
  constructor() {
    this.strings = [];
  }

  append(value) {
    this.strings.push(value);
    return this;
  }

  appendNewLine(value) {
    this.strings.push(value);
    this.strings.push("\n");
    return this;
  }

  toString() {
    return this.strings.join("");
  }

  clear() {
    this.strings = [];
    return this;
  }

  length() {
    return this.strings.reduce((total, str) => total + str.length, 0);
  }
}
