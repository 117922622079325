import { fetchProductsApi } from "../../api/productsApi";
import {
  EMS_DEFAULT_PAGE_NUMBER,
  EMS_DEFAULT_PAGE_SIZE,
} from "../../util/util-ems";

const INITIAL_PAGINATION_STATE = {
  page: EMS_DEFAULT_PAGE_NUMBER,
  size: EMS_DEFAULT_PAGE_SIZE,
  totalItems: 0,
  totalPages: 0,
};

const createProductSlice = (set, get) => ({
  products: [],
  loadingProducts: false,
  errorProducts: null,

  // Estado de Paginación
  pagination: INITIAL_PAGINATION_STATE,

  // Acciones de Paginación
  setPagination: (page, size) => {
    set((state) => ({
      pagination: {
        ...state.pagination,
        page,
        size,
      },
    }));
  },
  resetPagination: () => set({ pagination: INITIAL_PAGINATION_STATE }),

  fetchProducts: async (headers, logout) => {
    set({ loadingProducts: true, errorProducts: null });
    try {
      const {
        selectedFilters,
        searchInput,
        availableFilters,
        sortBy,
        sortDir,
        pagination,
      } = get();

      // Find final liafs in all the sublevels
      const getLeafNodes = (filterId, selectedOptions) => {
        const filter = availableFilters.find((f) => f.id === filterId);
        if (!filter || !filter.options) return selectedOptions;

        // Find all parent IDs that have children
        const parentIds = new Set();
        filter.options.forEach((option) => {
          if (option.subOptions && option.subOptions.length > 0) {
            parentIds.add(option.id);
            // If there are deeper nesting levels, add those parent IDs too
            option.subOptions.forEach((subOption) => {
              if (subOption.subOptions && subOption.subOptions.length > 0) {
                parentIds.add(subOption.id);
              }
            });
          }
        });

        // Filter out any selected options that are parents
        return selectedOptions.filter((optionId) => !parentIds.has(optionId));
      };

      const processedCheckboxFilters = selectedFilters.checkbox
        ? Object.entries(selectedFilters.checkbox).reduce(
            (acc, [filterId, selectedOptions]) => {
              // For each filter, only include the leaf nodes
              acc[filterId] = getLeafNodes(filterId, selectedOptions);
              return acc;
            },
            {}
          )
        : {};

      const params = new URLSearchParams({
        search: searchInput.trim(),
        ...processedCheckboxFilters,
        ...selectedFilters.range,
        ...selectedFilters.switch,
      });

      /*
      console.clear();
      console.log({
        firms: toString(processedCheckboxFilters?.firms),
        selectedFiltersFirms: toString(selectedFilters?.checkbox?.firms),
      });*/

      if (sortBy) params.append("sortBy", sortBy);
      if (sortDir) params.append("sortDir", sortDir);
      if (pagination.page) params.append("page", pagination.page);
      if (pagination.size) params.append("size", pagination.size);

      const { data, error } = await fetchProductsApi(headers, params, logout);
      if (data) {
        set({
          products: data.products,
          pagination: data.paginationInfo,
          loadingProducts: false,
        });
      } else {
        set({ errorProducts: error, loadingProducts: false });
      }
    } catch (error) {
      set({ errorProducts: error.message, loadingProducts: false });
    }
  },
  resetProductSlice: () => {
    set({
      products: [],
      loadingProducts: false,
      errorProducts: null,
      pagination: INITIAL_PAGINATION_STATE,
    });
  },
});

export default createProductSlice;
