import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  CircularProgress,
  Icon,
  Link,
  Typography,
} from "@material-ui/core";

import EmployeeStorePageWrapper from "../layout/EmployeeStorePageWrapper";
import {
  EMPLOYEE_STORE_HISTORY_ROUTE,
  //EMPLOYEE_STORE_HISTORY_ROUTE,
  EMPLOYEE_STORE_HOME_ROUTE,
  EMPLOYEE_STORE_SHOPPING_CART_ROUTE,
} from "../routes/EmployeeStoreRouter";
import { useQueryParameters } from "../../../core/hooks/useQueryParameters";
import useCheckout from "../store/hooks/useCheckout";
import {
  EMS_PAYMENT_STATUS_AUTHORIZED,
  INITIAL_VALIDATIONS_CHECKOUTS_RES_STATUSES,
  PAYMENT_SYSTEM_TRANSBANK_WEBPAY_PLUS,
  TBK_CANCEL,
  TBK_ERROR,
  TBK_TIMEOUT,
} from "../util/util-ems";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import useCart from "../store/hooks/useCart";
import PaymentCheckoutResult from "../components/checkout/PaymentCheckoutResult";
import useSimulation from "../store/hooks/useSimulation";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  emptyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 30,
    width: "100%",
    marginTop: "16px",
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
      overflow: "auto",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  loading: {
    color: "black",
  },
  linkBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  },
  linksContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
  },
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: 16,
};

export default function EmployeeStoreCheckoutPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const query = useQueryParameters();
  const [initialValidation, setInitialValidation] = useState({
    code: null,
    translation: null,
  });
  const [isValidated, setIsValidated] = useState(false);

  const { emsCartId, handleFetchCart } = useCart();

  const {
    commitTransaction,
    validatingPayment,
    paymentStatus,
    paymentSystemResponse,
    itemsDescription,
    cancelOrder,
  } = useCheckout();

  const { resetSimulation } = useSimulation();

  useEffect(() => {
    if (isValidated) return;

    const validate = async () => {
      setIsValidated(true);
      const NO_PAYMENT = query.get("NO_PAYMENT") === "true" ? true : false;

      if (NO_PAYMENT === true) {
        const body = {
          emsIdCompra: emsCartId,
        };

        const res = await commitTransaction({
          body,
        });

        if (res === true) {
          handleFetchCart();
          resetSimulation();
        }
      } else {
        const token_ws = query.get("token_ws") || null;
        const TBK_TOKEN = query.get("TBK_TOKEN") || null;
        const TBK_ID_SESION = query.get("TBK_ID_SESION") || null;
        const TBK_ORDEN_COMPRA = query.get("TBK_ORDEN_COMPRA") || null;

        // CASE 1 - TIMEOUT (10 minutes in QA and 4 minutes in PRD)
        if (
          isNil(token_ws) &&
          isNil(TBK_TOKEN) &&
          !isNil(TBK_ID_SESION) &&
          !isNil(TBK_ORDEN_COMPRA)
        ) {
          setInitialValidation({
            code: TBK_TIMEOUT,
            translation: INITIAL_VALIDATIONS_CHECKOUTS_RES_STATUSES.TBK_TIMEOUT,
          });
          // call cancel purchase endpoint
          await cancelOrder({ emsCompraId: emsCartId });
          return;
        }

        // CASE 2 - CANCEL PURCHASE IN TRANSBANK FORM
        if (
          isNil(token_ws) &&
          !isNil(TBK_TOKEN) &&
          !isNil(TBK_ID_SESION) &&
          !isNil(TBK_ORDEN_COMPRA)
        ) {
          setInitialValidation({
            code: TBK_CANCEL,
            translation: INITIAL_VALIDATIONS_CHECKOUTS_RES_STATUSES.TBK_CANCEL,
          });
          // call cancel purchase endpoint
          await cancelOrder({ emsCompraId: emsCartId });
          return;
        }

        // CASE 3 - ERROR IN TRANSBANK (ULTRA RARE)
        if (
          !isNil(token_ws) &&
          !isNil(TBK_TOKEN) &&
          !isNil(TBK_ID_SESION) &&
          !isNil(TBK_ORDEN_COMPRA)
        ) {
          setInitialValidation({
            code: TBK_ERROR,
            translation: INITIAL_VALIDATIONS_CHECKOUTS_RES_STATUSES.TBK_ERROR,
          });

          // call cancel purchase endpoint
          await cancelOrder({ emsCompraId: emsCartId });
          return;
        }

        // CASE 4&5 - PURCHASE APPROVED/REJECTED (must commit the transaction using token_ws)
        const body = {
          paymentSystem: PAYMENT_SYSTEM_TRANSBANK_WEBPAY_PLUS,
          transactionToken: token_ws,
          TBK_TOKEN,
          TBK_ID_SESION,
          TBK_ORDEN_COMPRA,
          emsIdCompra: emsCartId,
        };

        const res = await commitTransaction({
          body,
        });

        if (res === true) {
          handleFetchCart();
          resetSimulation();
        }
      }
    };

    validate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, isValidated]);

  return (
    <EmployeeStorePageWrapper applyOverflow={true}>
      {validatingPayment ? (
        <div className={classes.emptyContainer}>
          <Typography variant="h4">
            {t("EMPLOYEE_STORE.VALIDATING_PAYMENT")}
          </Typography>
          <CircularProgress className={classes.loading} size={32} />
        </div>
      ) : (
        <div className={classes.emptyContainer}>
          {!isNil(initialValidation?.code) ||
          paymentStatus !== EMS_PAYMENT_STATUS_AUTHORIZED ? (
            <Box className={classes.linksContainer}>
              <Box className={classes.linkBox}>
                <Icon
                  className="fas fa-shopping-cart"
                  style={commonIconStyles}
                />
                <Link
                  onClick={() =>
                    history.push(EMPLOYEE_STORE_SHOPPING_CART_ROUTE)
                  }
                  className={classes.link}
                >
                  {t("EMPLOYEE_STORE.HEADER_BTN_SHOPPING_CART")}
                </Link>
              </Box>
              <Box className={classes.linkBox}>
                <Icon className="fas fa-home" style={commonIconStyles} />
                <Link
                  onClick={() => history.push(EMPLOYEE_STORE_HOME_ROUTE)}
                  className={classes.link}
                >
                  {t("EMPLOYEE_STORE.DIALOG_EMS_CLOSED_YES_LIST_0")}
                </Link>
              </Box>
            </Box>
          ) : (
            <Box className={classes.linksContainer}>
              <Box className={classes.linkBox}>
                <Icon className="fas fa-history" style={commonIconStyles} />
                <Link
                  onClick={() => history.push(EMPLOYEE_STORE_HISTORY_ROUTE)}
                  className={classes.link}
                >
                  {t("EMPLOYEE_STORE.HEADER_BTN_HISTORY")}
                </Link>
              </Box>

              <Box className={classes.linkBox}>
                <Icon className="fas fa-home" style={commonIconStyles} />
                <Link
                  onClick={() => history.push(EMPLOYEE_STORE_HOME_ROUTE)}
                  className={classes.link}
                >
                  {t("EMPLOYEE_STORE.DIALOG_EMS_CLOSED_YES_LIST_0")}
                </Link>
              </Box>
            </Box>
          )}

          {!isNil(initialValidation?.code) ? (
            <Typography variant="h4">
              {t(initialValidation?.translation)}
            </Typography>
          ) : (
            <PaymentCheckoutResult
              paymentStatus={paymentStatus}
              paymentSystemResponse={paymentSystemResponse}
              itemsDescription={itemsDescription}
            />
          )}
        </div>
      )}
    </EmployeeStorePageWrapper>
  );
}
