import create from "zustand";
import { persist } from "zustand/middleware";

import createBagSlice from "./slices/createBagSlice";
import createCheckoutSlice from "./slices/createCheckoutSlice";
import createBannerSlice from "./slices/createBannerSlice";
import createQuickViewSlice from "./slices/createQuickViewSlice";
import createProductSlice from "./slices/createProductSlice";
import createFavoriteSlice from "./slices/createFavoriteSlice";
import createFilterSlice from "./slices/createFilterSlice";
import createInfoSlice from "./slices/createInfoSlice";
import createHistorySlice from "./slices/createHistorySlice";

const EMS_STORE_STORAGE_NAME = "ems-store";

const useEmployeeStoreState = create(
  persist(
    (set, get) => ({
      ...createBagSlice(set, get),
      ...createCheckoutSlice(set, get),
      ...createBannerSlice(set, get),
      ...createQuickViewSlice(set, get),
      ...createProductSlice(set, get),
      ...createFavoriteSlice(set, get),
      ...createFilterSlice(set, get),
      ...createInfoSlice(set, get),
      ...createHistorySlice(set, get),
    }),
    {
      name: EMS_STORE_STORAGE_NAME,
      partialize: (state) => ({
        // banners
        bannerVisible: state.bannerVisible,
        // cart
        emsCartId: state.emsCartId,
        cartItems: state.cartItems,
        emsCartLastModification: state.emsCartLastModification,
        // favs
        favorites: state.favorites,
        // checkout
        //checkoutStep: state.checkoutStep,
        // simulation
        //isSimulating: state.isSimulating,
        //simulationsIds: state.simulationsIds,
        //parentSimulationId: state.parentSimulationId,
        //responseSimulations: Array.from(state.responseSimulations.entries()),
        //simulationCancelled: state.simulationCancelled,
      }),
      // Custom serialization to handle Map objects
      // serialize: (state) => JSON.stringify(state),
      // deserialize: (str) => {
      //   const parsed = JSON.parse(str);
      //   // Convert array back to Map after deserialization
      //   if (parsed.state.responseSimulations) {
      //     parsed.state.responseSimulations = new Map(
      //       parsed.state.responseSimulations
      //     );
      //   }
      //   return parsed;
      // },
    }
  )
);

// Sincroniza el estado entre pestañas
window.addEventListener("storage", (event) => {
  if (event?.key === EMS_STORE_STORAGE_NAME) {
    useEmployeeStoreState.persist.rehydrate();
  }
});

export default useEmployeeStoreState;
