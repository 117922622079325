import { has, isArray, isEmpty, isNil, isObject, toNumber } from "lodash";
import { saveAs } from "file-saver";

import {
  METHOD_POST,
  SERVER_HOST,
  SERVICE_EXE_URL,
} from "../../../../util/Constants";
import {
  base64ToArrayBuffer,
  saveByteArrayToFile,
} from "../../../../util/UtilFormat";

function fixValuesType(params) {
  if (isNil(params) || !isObject(params)) {
    return params;
  }

  let result = {};
  for (const [key, value] of Object.entries(params)) {
    if (isNil(value)) {
      result[key] = value;
      continue;
    }

    if (typeof value === "string" && /^\d+$/.test(value)) {
      result[key] = toNumber(value);
    } else {
      result[key] = value;
    }
  }

  return result;
}
export class CliGenFilesGenerator {
  //Method to implement
  static async execute(props) {
    if (
      !isNil(props) &&
      !isNil(props.serviceBody) &&
      !isNil(props.serviceBody.requestData) &&
      !isEmpty(props.serviceBody.requestData) &&
      has(props.serviceBody.requestData, "params")
    ) {
      const serviceBody = props.serviceBody;
      const newFixedValuesInParams = fixValuesType(
        serviceBody?.requestData?.params
      );
      const fixedValues = {
        ...serviceBody,
        requestData: {
          ...serviceBody.requestData,
          params: newFixedValuesInParams,
        },
      };

      try {
        const url = SERVER_HOST() + SERVICE_EXE_URL;
        const response = await fetch(url, {
          body: JSON.stringify(fixedValues),
          method: METHOD_POST,
          headers: props.REQUEST_HEADERS,
        });

        if (response) {
          const jsonResponse = await response.json();
          if (jsonResponse) {
            const { ok, errorMsg, dataResponse } = jsonResponse;
            if (ok) {
              if (
                !isNil(dataResponse) &&
                isArray(dataResponse) &&
                !isEmpty(dataResponse)
              ) {
                for (const dr in dataResponse) {
                  const file = dataResponse[dr];
                  if (!isNil(file) && !isNil(file.name)) {
                    if (!isNil(file.bytes) && !isEmpty(file.bytes)) {
                      const sampleArr = base64ToArrayBuffer(file.bytes);
                      saveByteArrayToFile(file.name, sampleArr);
                    } else if (!isNil(file.url)) {
                      saveAs(file.url, file.name);
                    }
                  }
                }
                return [true, "", {}];
              } else {
                return [true, "", {}];
              }
            } else {
              return [
                false,
                `${props?.serviceBody?.errorMessage}: ${errorMsg}`,
                {},
              ];
            }
          }
        } else {
          return [false, "ERROR_RESOURCE_NOT_FOUND_TEXT", {}];
        }
      } catch (e) {
        return [false, "ERROR_RESOURCE_NOT_FOUND_TEXT", {}];
      }
    } else {
      return [false, "CLI_SERVICE_DEFAULT_WRONG_DEF", {}];
    }
  }

  static hasToSubstituteValuesInParams() {
    return true;
  }

  static hasToThrowToast() {
    return true;
  }
}
