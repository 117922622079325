import React, { useCallback, useEffect } from "react";
import {
  Badge,
  CircularProgress,
  Icon,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

import {
  EMPLOYEE_STORE_PREFIX_ROUTE,
  EMPLOYEE_STORE_SHOPPING_CART_ROUTE,
  EMPLOYEE_STORE_SHOPPING_CHECKOUT_ROUTE,
} from "../../../routes/EmployeeStoreRouter";
import { size } from "lodash";
import useCart from "../../../store/hooks/useCart";

const commonIconStyles = {
  width: "auto",
  height: "aut0",
  padding: 2,
};

export default function EmployeStoreBag() {
  let history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const isCartPage =
    location.pathname ===
    `${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_SHOPPING_CART_ROUTE}`;

  const isCheckoutPage =
    location.pathname ===
    `${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_SHOPPING_CHECKOUT_ROUTE}`;

  const { handleFetchCart, loadingCart, cartItems } = useCart();
  const sizeCartItems = size(cartItems) || 0;

  const navigateToCartAndFetch = useCallback(() => {
    handleFetchCart();
    history.push(EMPLOYEE_STORE_SHOPPING_CART_ROUTE);
  }, [handleFetchCart, history]);

  useEffect(() => {
    handleFetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingCart && !isCartPage && !isCheckoutPage) {
    return <CircularProgress size={18} />;
  }

  if (loadingCart && isCartPage && isCheckoutPage) {
    return null;
  }

  return (
    <Tooltip title={t("EMPLOYEE_STORE.HEADER_BTN_SHOPPING_CART")}>
      <IconButton
        onClick={navigateToCartAndFetch}
        aria-label="shopping-cart-button"
      >
        <Badge badgeContent={sizeCartItems} color="error">
          <Icon className="fas fa-shopping-cart" style={commonIconStyles} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
