import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import QuantityControlButton from "./QuantityControlButton";
import AddToCartButton from "./AddToCartButton";
import useCheckout from "../store/hooks/useCheckout";
import useSimulation from "../store/hooks/useSimulation";
import useCart from "../store/hooks/useCart";
import { isArray, isEmpty, isNil, toNumber } from "lodash";
import useInfo from "../store/hooks/useInfo";
import useEmsButtons from "../store/hooks/useEmsButtons";
import TooltipDisablesEmsBtns from "./TooltipDisablesEmsBtns";

export const BUTTONS_HEIGHT = 32;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  containerDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: theme.spacing(1),
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
}));

const ProductActions = ({ item, isShowedInFav, isShowedInDetails }) => {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(item.minQuantity);
  const { checkoutStep } = useCheckout();
  const { isSimulating } = useSimulation();
  const { loadingCart, cartItems } = useCart();
  const { initialInfo } = useInfo();

  const productHasPrice = toNumber(item?.price) > 0;
  const productHasStock = toNumber(item?.stock) > 0;
  const productHasEnoughStock =
    productHasStock && toNumber(item?.stock) >= toNumber(item?.minQuantity);

  const buttonsDisabled = useEmsButtons({
    checkoutStep,
    isSimulating,
    loadingCart,
    productHasPrice,
    productHasStock,
    productHasEnoughStock,
    initialInfo,
    insideCart: false,
  });

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  const isProductInCart = useMemo(() => {
    return (
      !isNil(cartItems) &&
      !isEmpty(cartItems) &&
      isArray(cartItems) &&
      cartItems.some((x) => x?.ean === item?.ean)
    );
  }, [cartItems, item]);

  const disableRemove = isProductInCart || isShowedInFav;

  return (
    <TooltipDisablesEmsBtns
      buttonsDisabled={buttonsDisabled}
      forceHide={isProductInCart && disableRemove === true}
    >
      <div
        className={
          isShowedInDetails ? classes.containerDetails : classes.container
        }
      >
        {!isProductInCart && (
          <QuantityControlButton
            minQuantity={item.minQuantity}
            onChange={handleQuantityChange}
            defaultQuantity={item.minQuantity}
            disabled={buttonsDisabled.isDisabled}
            stock={toNumber(item?.stock)}
          />
        )}
        <AddToCartButton
          item={item}
          externalQuantity={quantity}
          disabled={buttonsDisabled.isDisabled}
          disableRemove={disableRemove}
        />
      </div>
    </TooltipDisablesEmsBtns>
  );
};

export default ProductActions;
