import { useState, useCallback, useContext } from "react";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { fetchContactInfoApi } from "../../api/infoApi";

const useContactInfo = () => {
  const { t } = useTranslation();
  const headers = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);

  const fetchContactInfo = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchContactInfoApi(headers, logout);
      if (!isNil(response)) {
        const { error, data } = response;
        if (!isNil(error)) {
          setError(error);
        } else {
          setContactInfo(data);
        }
      } else {
        setError(t("ERROR_RESOURCE_NOT_FOUND_TEXT"));
      }
    } catch (err) {
      setError(t("ERROR_RESOURCE_NOT_FOUND_TEXT"));
    } finally {
      setLoading(false);
    }
  }, [headers, logout, t]);

  return {
    loading,
    error,
    contactInfo,
    fetchContactInfo,
  };
};

export default useContactInfo;
