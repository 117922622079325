import React, { useState, useEffect, useRef, useMemo } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import * as _ from "lodash";
import {
  defineInputEnabledByOperation,
  getCompletePath,
  resolveFieldAgainstShadowStatus,
} from "../../../util/UtilForm";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
import { InputLabel } from "@material-ui/core";

export default function SimpleComboBoxField(props) {
  //#region [Definitions]
  const { idSchema, schema, formData, formContext } = props;
  //Destr important attrs
  const {
    value: comboValue,
    optionsValues,
    presentationInfo: pi,
    isSavedEntity,
  } = formData || {};
  const {
    operation,
    section,
    block,
    formInstanceId,
    notifySetValueToServer,
    pre,
    shadowStatus,
    isBlockDisabledByWizard
  } = formContext;
  const [availableOptions, setAvailableOptions] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const comboRef = useRef(null);
  const [comboState, setComboState] = useState("");
  const [presentationInfo, setPresentationInfo] = useState(null);
  const [uniqueOptionsWasAddedByValue, setUniqueOptionsWasAddedByValue] =
    useState({
      shouldDisable: false,
      prevOptions: [],
    });

  //#endregion

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema,
    operation,
    isSavedEntity,
  });

  useEffect(() => {
    setAvailableOptions(optionsValues);
  }, [optionsValues]);

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      schema?.canEdit,
      schema?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }
    if (
      _.isNil(availableOptions) ||
      _.isEmpty(availableOptions) ||
      uniqueOptionsWasAddedByValue?.shouldDisable === true
    ) {
      setIsEnabled(false);
    }
  }, [operation, schema, availableOptions, uniqueOptionsWasAddedByValue]);

  useEffect(() => {
    if (comboValue === null) {
      setComboState("");
    } else {
      setComboState(comboValue);
    }
  }, [comboValue, formData]);

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  const handleChange = (value) => {
    setComboState(value);

    const valueToUpdateInTable = _.isEqual(value, "") ? null : value;
    notifySetValueToServer(
      formInstanceId,
      completeIdControl,
      valueToUpdateInTable,
      0,
      comboRef
    );
  };

  //Input styles
  const controlStyles = {
    width: "100%",
    color: presentationInfo && presentationInfo.color && presentationInfo.color,
    fontSize:
      presentationInfo &&
      presentationInfo.fontSize &&
      presentationInfo.fontSize,
    backgroundColor:
      presentationInfo &&
      presentationInfo.backgroundColor &&
      presentationInfo.backgroundColor,
    fontWeight:
      presentationInfo &&
      presentationInfo.fontWeight &&
      presentationInfo.fontWeight,
    fontStyle:
      presentationInfo &&
      presentationInfo.fontStyle &&
      presentationInfo.fontStyle,
  };

  const isClearabale = useMemo(() => {
    if (
      _.isNil(schema) ||
      _.isNil(comboValue) ||
      _.isNil(schema?.isClearable) ||
      _.trim(_.toString(comboValue)) === ""
    ) {
      return true;
    }

    const definedIsClearable = schema?.isClearable;
    if (_.isBoolean(definedIsClearable)) {
      return definedIsClearable;
    } else if (_.isString(definedIsClearable)) {
      const valueInShadowStatus = resolveFieldAgainstShadowStatus({
        field: definedIsClearable,
        shadowStatus,
        completeIdControl: definedIsClearable,
      });
      return (
        _.isEqual("S", _.toUpper(_.toString(valueInShadowStatus))) ||
        _.isEqual("TRUE", _.toUpper(_.toString(valueInShadowStatus))) ||
        _.isEqual("Y", _.toUpper(_.toString(valueInShadowStatus)))
      );
    } else {
      return true;
    }
  }, [schema, comboValue, shadowStatus]);

  //To add value in options if they are null
  useEffect(() => {
    if (
      (_.isNil(availableOptions) || _.isEmpty(availableOptions)) &&
      !_.isNil(comboState) &&
      comboState !== ""
    ) {
      const newComboState = [comboState];
      setAvailableOptions(newComboState);
      setUniqueOptionsWasAddedByValue({
        shouldDisable: true,
        prevOptions: newComboState,
      });
    }

    if (
      !_.isNil(availableOptions) &&
      !_.isEmpty(availableOptions) &&
      uniqueOptionsWasAddedByValue?.shouldDisable === true
    ) {
      const areEqualsToPrev = _.isEqual(
        availableOptions,
        uniqueOptionsWasAddedByValue?.prevOptions
      );
      if (areEqualsToPrev === false) {
        setUniqueOptionsWasAddedByValue({
          shouldDisable: false,
          prevOptions: availableOptions,
        });
      }
    }
  }, [comboState, availableOptions, uniqueOptionsWasAddedByValue]);

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      presentationInfo={presentationInfo}
      shadowStatus={shadowStatus}
    >
      <InputLabel htmlFor={completeIdControl} />
      <NativeSelect
        value={comboState}
        onChange={(e) => handleChange(e.target.value)}
        disabled={
          !isEnabled ||
          (presentationInfo && presentationInfo?.enabled === false) ||
          disableDueChangeSavedValue ||
          isBlockDisabledByWizard === true
        }
        style={controlStyles}
        inputRef={comboRef}
        //onBlur={(e) => handleChange(comboState)}
        id={completeIdControl}
        error={false}
        inputProps={{
          id: completeIdControl,
        }}
        //error={errorMap.some((e) => e && e.key === completeIdControl)}
      >
        {isClearabale && <option aria-label="None" value="" />}
        {!_.isNil(availableOptions) &&
          !_.isEmpty(availableOptions) &&
          availableOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
      </NativeSelect>
    </SimpleFieldContainer>
  );
}
