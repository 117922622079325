import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useInfo from "../../store/hooks/useInfo";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { isNil, toUpper } from "lodash";
import { AuthContext } from "../../../../core/providers/AuthContext";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles((theme) => ({
  bodySubtitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
    flexDirection: "row",
    marginBottom: 12,
  },
  actionBtn: {
    textTransform: "none",
    fontSize: 16,
  },
  emsLogoIcon: {
    width: 30,
  },
  body2: {
    marginTop: 12,
    fontStyle: "italic",
  },
  buttonLogoutRoot: {
    padding: 10,
    height: 30,
    marginTop: 12,
    backgroundColor: `${theme.palette.content.mainColor}`,
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}`,
      transform: "scale(1.05)",
    },
  },
  buttonLogoutLabel: {
    textTransform: "none",
    gap: 5,
  },
  logoutTypo: {
    color: "white",
  },
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export default function EmployeeStoreLayoutEmsCountryMissmatchDialog() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { auth, logout } = useContext(AuthContext);

  const {
    initialInfo,
    firstInitialInfoCompleted,
    showErrorEmsCountryMissmatch,
  } = useInfo();

  const hasToOpen = useMemo(() => {
    return (
      !isNil(initialInfo) &&
      firstInitialInfoCompleted === true &&
      showErrorEmsCountryMissmatch
    );
  }, [firstInitialInfoCompleted, initialInfo, showErrorEmsCountryMissmatch]);

  return (
    <Dialog open={hasToOpen} maxWidth="lg">
      <DialogContent>
        <DialogContentText variant="h4">{"Sesión Invalida"}</DialogContentText>
        <Box className={classes.bodySubtitle}>
          <Icon
            className={"fas fa-info-circle"}
            style={{ ...commonIconStyles }}
            fontSize="medium"
          />
          <Typography variant="h6">
            {
              "Se ha detectado países de tienda distintos entre su sesión actual y lo configurado para su usuario en la base de datos."
            }
          </Typography>
        </Box>
        <Typography variant="h6">
          {`- Pais en Sesión [${toUpper(auth?.emsCountry)}] `}
          {toUpper(auth?.emsCountry) === "XX" ? null : (
            <ReactCountryFlag
              svg
              countryCode={auth?.emsCountry}
              style={{ marginRight: 12 }}
            />
          )}
        </Typography>
        <Typography variant="h6">
          {`- Pais en Base de Datos [${toUpper(
            initialInfo?.configuredUserEmsCountry
          )}] `}
          {toUpper(initialInfo?.configuredUserEmsCountry) === "XX" ? null : (
            <ReactCountryFlag
              svg
              countryCode={initialInfo?.configuredUserEmsCountry}
              style={{ marginRight: 12 }}
            />
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          classes={{
            root: classes.buttonLogoutRoot,
            label: classes.buttonLogoutLabel,
          }}
          onClick={() => {
            logout(false);
          }}
        >
          <Icon className="fas fa-sign-out-alt" style={{ color: "white" }} />
          <Typography className={classes.logoutTypo}>
            {t("TOOLTIP_LOGOUT")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
