import React, { useMemo, useState } from "react";
import { isNil } from "lodash";
import HelpDialogRenderBody from "./HelpDialogRenderBody";

const INITIAL_STATE = {
  open: false,
  path: null,
  idForm: null,
  nameForm: null,
  isPolicy: false
};

export const useHelpDialog = () => {
  const [state, setState] = useState(INITIAL_STATE);

  const handleOpen = ({ path, idForm, nameForm, isPolicy}) => {
    setState({ open: true, path, idForm, nameForm, isPolicy });
  };

  const handleClose = () => {
    setState(INITIAL_STATE);
  };

  const { open, path, idForm, nameForm, isPolicy} = state;

  const HelpDialogRender = useMemo(
    () => () => {
      if (open === true && !isNil(path) && !isNil(idForm)) {
        return (
          <HelpDialogRenderBody
            open={open}
            path={path}
            idForm={idForm}
            handleClose={handleClose}
            nameForm={nameForm}
            isPolicy={isPolicy}
          />
        );
      } else {
        return <></>;
      }
    },
    [open, path, idForm, nameForm, isPolicy]
  );

  return { HelpDialogRender, handleOpen };
};
