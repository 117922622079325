import React, { useState, useEffect, useRef, useMemo } from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import * as _ from "lodash";
import { add, format, isAfter, isBefore, isValid, parse } from "date-fns";
import { toast } from "react-toastify";

import {
  applyPreservedEvent,
  defineInputEnabledByOperation,
  getCompletePath,
  preserveFormEvent,
} from "../../../util/UtilForm";
import { useTranslation } from "react-i18next";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import {
  CALENDAR_BTN_SUFFIX,
  DATE_TIME_PATTERN,
  FORM_OPERATION_NEW,
  FRONT_DATE_TIME_PATTERN,
  MAX_DATE_FORM,
  MIN_DATE_FORM,
  PADDING_ICON_BUTTONS_FORMS,
  TOAST_CONTAINER_FORM_CONTAINER,
  DATE_PATTERN
} from "../../../util/Constants";
import { createStringDateAsUTC, localDateToUtc } from "../../../util/UtilDates";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
import { useLastWasTab } from "../../../core/hooks/useLastWasTab";

export default function SimpleDateTimePickerField(props) {
  //#region [Definitions]
  const { idSchema, schema, formData, formContext } = props;
  //Destr important attrs
  const { value, presentationInfo: pi, isSavedEntity } = formData || {};
  const [selectedDate, setSelectedDate] = useState(null);
  const [isEnabled, setIsEnabled] = useState(true);
  const { t } = useTranslation();
  const {
    operation,
    section,
    block,
    formInstanceId,
    notifySetValueToServer,
    pre,
    isBlockDisabledByWizard,
    shadowStatus
  } = formContext;
  const dateTimePickerRef = useRef(null);
  const [presentationInfo, setPresentationInfo] = useState(null);
  //#endregion

  const formatToApply =
    schema && schema.dateFormat ? schema.dateFormat : FRONT_DATE_TIME_PATTERN;

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema,
    operation,
    isSavedEntity,
  });

  const { lastWasTab, handleKeyDown } = useLastWasTab();

  //Clearing function
  useEffect(() => {
    if (!_.isNil(value) && value !== "") {
      const newDate = parse(value, DATE_TIME_PATTERN, new Date());
      setSelectedDate(createStringDateAsUTC(newDate));
    } else {
      setSelectedDate(null);
    }
  }, [value, formData]);

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      schema?.canEdit,
      schema?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }
  }, [operation, schema]);

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  const maxDateDefinedInForm = useMemo(() => {
    if (
      !_.isNil(schema) &&
      !_.isNil(schema.maxDate) &&
      isValid(parse(schema.maxDate, DATE_PATTERN, new Date()))
    ) {
      try {
        const maxDateDefined = parse(schema.maxDate, DATE_PATTERN, new Date());
        const maxDateDefinedPlusOneDay = add(maxDateDefined, { days: 1 });
        return maxDateDefinedPlusOneDay;
      } catch (_) {
        return null;
      }
    } else {
      return null;
    }
  }, [schema]);

  const minDateDefinedInForm = useMemo(() => {
    if (
      !_.isNil(schema) &&
      !_.isNil(schema.minDate) &&
      isValid(parse(schema.minDate, DATE_PATTERN, new Date()))
    ) {
      try {
        return parse(schema.minDate, DATE_PATTERN, new Date());
      } catch (_) {
        return null;
      }
    } else {
      return null;
    }
  }, [schema]);

  const handleChange = async (newValue, toSend, e) => {
    const preservedEvent = preserveFormEvent({
      event: e,
      lastWasTab,
      formInstanceId,
    });

    const isAfterMaxDate =
      isValid(newValue) &&
      isAfter(newValue, maxDateDefinedInForm || MAX_DATE_FORM);
    const isBeforeMinDate =
      isValid(newValue) &&
      isBefore(newValue, minDateDefinedInForm || MIN_DATE_FORM);

    const isOutRange =
      newValue != null &&
      isValid(newValue) &&
      (isAfterMaxDate || isBeforeMinDate);

    if (isOutRange && toSend) {
      toast.error(t("DATE_PICKERS_OUT_OF_RANGE"), {
        containerId: TOAST_CONTAINER_FORM_CONTAINER,
      });
    }

    const value = isOutRange && toSend ? null : newValue;

    setSelectedDate(value);
    //For form context, format date to schema definition
    if (toSend) {
      if (isValid(value)) {
        const formattedDate = format(localDateToUtc(value), DATE_TIME_PATTERN);
        //Line always 0, its head field
        await notifySetValueToServer(
          formInstanceId,
          completeIdControl,
          formattedDate,
          0,
          null
        );
      } else {
        await notifySetValueToServer(
          formInstanceId,
          completeIdControl,
          value,
          0,
          null
        );
      }
      applyPreservedEvent({ preservedEvent });
    }
  };

  //Input styles
  const controlStyles = {
    base: {
      width: "100%",
      backgroundColor:
        presentationInfo &&
        presentationInfo.backgroundColor &&
        presentationInfo.backgroundColor,
    },
    inner: {
      color:
        presentationInfo && presentationInfo.color && presentationInfo.color,
      fontSize:
        presentationInfo &&
        presentationInfo.fontSize &&
        presentationInfo.fontSize,

      fontWeight:
        presentationInfo &&
        presentationInfo.fontWeight &&
        presentationInfo.fontWeight,
      fontStyle:
        presentationInfo &&
        presentationInfo.fontStyle &&
        presentationInfo.fontStyle,
    },
  };

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      addUTCTooltip={true}
      presentationInfo={presentationInfo}
      shadowStatus={shadowStatus}
    >
      <KeyboardDateTimePicker
        id={completeIdControl}
        disabled={
          !isEnabled ||
          (presentationInfo && presentationInfo?.enabled === false) ||
          disableDueChangeSavedValue ||
          isBlockDisabledByWizard
        }
        value={selectedDate}
        //onChange={(date) => handleChange(date)}
        onChange={(date) => handleChange(date, false)}
        onBlur={(e) => handleChange(selectedDate, true, e)}
        onAccept={(date) => handleChange(date, true)}
        onKeyDown={handleKeyDown}
        format={formatToApply}
        //invalidDateMessage={t("INVALID_DATE")}
        invalidDateMessage=""
        initialFocusedDate={new Date()}
        disablePast={
          schema && schema.disablePast && FORM_OPERATION_NEW === operation
            ? schema.disablePast
            : false
        }
        disableFuture={
          schema && schema.disableFuture && FORM_OPERATION_NEW === operation
            ? schema.disableFuture
            : false
        }
        variant="dialog"
        ampm={false}
        inputRef={dateTimePickerRef}
        error={false}
        //error={errorMap.some((e) => e && e.key === completeIdControl)}
        KeyboardButtonProps={{
          id: `${completeIdControl}${CALENDAR_BTN_SUFFIX}`,
          style: {
            padding: PADDING_ICON_BUTTONS_FORMS,
          },
          tabIndex: "-1",
        }}
        style={controlStyles.base}
        InputProps={{
          style: controlStyles.inner,
        }}
        cancelLabel={t("DATE_PICKERS_CANCEL")}
        okLabel={t("DATE_PICKERS_OK")}
        maxDateMessage={null}
        minDateMessage={null}
        maxDate={maxDateDefinedInForm || MAX_DATE_FORM}
        minDate={minDateDefinedInForm || MIN_DATE_FORM}
      />
    </SimpleFieldContainer>
  );
}
