import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useInfo from "../../store/hooks/useInfo";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { isNil, toUpper } from "lodash";
import ReactCountryFlag from "react-country-flag";
import { AuthContext } from "../../../../core/providers/AuthContext";

const useStyles = makeStyles(() => ({
  bodySubtitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
    flexDirection: "row",
  },
  actionBtn: {
    textTransform: "none",
    fontSize: 16,
  },
  emsLogoIcon: {
    width: 30,
  },
  body2: {
    marginTop: 12,
    fontStyle: "italic",
  },
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export default function EmployeeStoreLayoutEmsNoActiveCatalogDialog() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { auth } = useContext(AuthContext);
  const {
    initialInfo,
    firstInitialInfoCompleted,
    showDialogNoActiveCatalog,
    openNoActiveCatalogDialog,
    closeNoActiveCatalogDialog,
  } = useInfo();

  useEffect(() => {
    if (!isNil(initialInfo) && firstInitialInfoCompleted === true) {
      const { showDialogNoActiveCatalog } = initialInfo;
      if (showDialogNoActiveCatalog === true) {
        openNoActiveCatalogDialog();
      }
    }
  }, [firstInitialInfoCompleted, initialInfo, openNoActiveCatalogDialog]);

  return (
    <Dialog open={showDialogNoActiveCatalog} maxWidth="lg">
      <DialogContent>
        <DialogContentText variant="h4">
          {toUpper(auth?.emsCountry) === "XX" ? null : (
            <ReactCountryFlag
              svg
              countryCode={auth?.emsCountry}
              style={{ marginRight: 12 }}
            />
          )}

          {t("EMPLOYEE_STORE.DIALOG_NO_ACTIVE_CATALOG_TITLE")}
        </DialogContentText>
        <Box className={classes.bodySubtitle}>
          <Icon
            className={"fas fa-info-circle"}
            style={{ ...commonIconStyles }}
            fontSize="medium"
          />
          <Typography variant="h6">
            {t("EMPLOYEE_STORE.DIALOG_NO_ACTIVE_CATALOG_BODY")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeNoActiveCatalogDialog}
          color="primary"
          className={classes.actionBtn}
          endIcon={
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/employee-store/employee-store-icon-light.webp`}
              alt="store-logo"
              className={classes.emsLogoIcon}
            />
          }
        >
          {`${t("EMPLOYEE_STORE.DIALOG_EMS_CLOSED_ACTION")} ${t(
            "EMPLOYEE_STORE.DISPLAY_NAME"
          )}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
