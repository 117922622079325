import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { BUTTONS_HEIGHT } from "./ProductActions";
import { toast } from "react-toastify";
import { TOAST_CONTAINER_LAYOUT } from "../../../util/Constants";
import { useTranslation } from "react-i18next";
import { toNumber } from "lodash";

const useStyles = makeStyles((theme) => ({
  quantityControl: {
    height: BUTTONS_HEIGHT,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    border: `1px solid ${theme.palette.secondary.main}`,
    width: "100%",
    justifyContent: "center",
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      minWidth: 125,
    },
  },
  quantityLabelContainer: {
    padding: "0px 12px",
    borderRadius: 6,
    color: "#FFF",
    backgroundColor: theme.palette.content.mainColor,
  },
  quantityLabelTypo: {
    fontWeight: "bold",
  },
  quantityLabelTypoDisabled: {
    color: "#ffffffcf",
    fontWeight: "bold",
  },
  amountBtn: {
    color: theme.palette.header.textColor,
  },
}));

const QuantityControlButton = ({
  minQuantity,
  onChange,
  disabled = false,
  defaultQuantity,
  stock,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [quantity, setQuantity] = useState(defaultQuantity);

  const handleIncrement = () => {
    const newQuantity = quantity + minQuantity;
    if (newQuantity > toNumber(stock)) {
      toast.info(t("EMPLOYEE_STORE.CONTROL_ADD_MORE_THAN_STOCK"), {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: "max-stock-reached",
      });
    } else {
      setQuantity(newQuantity);
      onChange(newQuantity);
    }
  };

  const handleDecrement = () => {
    if (quantity > minQuantity) {
      const newQuantity = quantity - minQuantity;
      setQuantity(newQuantity);
      onChange(newQuantity);
    }
  };

  return (
    <div className={classes.quantityControl}>
      <IconButton
        size="small"
        className={classes.amountBtn}
        onClick={handleDecrement}
        disabled={disabled}
      >
        <RemoveIcon />
      </IconButton>
      <div className={classes.quantityLabelContainer}>
        <Typography
          component="span"
          variant="h6"
          className={
            disabled
              ? classes.quantityLabelTypoDisabled
              : classes.quantityLabelTypo
          }
        >
          {quantity}
        </Typography>
      </div>
      <IconButton
        size="small"
        className={classes.amountBtn}
        onClick={handleIncrement}
        disabled={disabled}
      >
        <AddIcon />
      </IconButton>
    </div>
  );
};

export default QuantityControlButton;
