import React from "react";
import { FormControlLabel, makeStyles, Switch } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useInfo from "../store/hooks/useInfo";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  formLabel: {
    marginLeft: 2,
  },
}));

export default function OpenEmsByDefaultSwitch() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loadingInfo, initialInfo, handleChangeOpenEmsByDefault } = useInfo();

  if (loadingInfo) {
    return (
      <Skeleton
        width={"100%"}
        height={25}
        variant="rect"
        style={{ marginTop: 12 }}
      />
    );
  }

  return (
    <FormControlLabel
      value="start"
      control={
        <Switch
          color="primary"
          defaultChecked={initialInfo?.openEmsByDefault === true}
        />
      }
      label={t("EMPLOYEE_STORE.EMS_BY_DEFAULT")}
      labelPlacement="start"
      className={classes.formLabel}
      onChange={(_, checked) => handleChangeOpenEmsByDefault(checked)}
    />
  );
}
