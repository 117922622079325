import { Tooltip } from "@material-ui/core";
import { isNil } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

export default function TooltipDisablesEmsBtns({
  buttonsDisabled,
  children,
  overridePlacement,
  forceHide,
}) {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t(buttonsDisabled?.disabledText)}
      disableHoverListener={
        isNil(buttonsDisabled) ||
        isNil(buttonsDisabled?.disabledText) ||
        buttonsDisabled?.isDisabled !== true ||
        forceHide === true
      }
      placement={overridePlacement || undefined}
    >
      {children}
    </Tooltip>
  );
}
