import React, { useState, useRef, useContext, useEffect } from "react";

import { CSSTransition } from "react-transition-group";
import {
  Badge,
  Button,
  ClickAwayListener,
  Hidden,
  Icon,
  IconButton,
  Radio,
  Switch,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import "./transitions.css";
import {
  itemIconStart,
  useStyles,
  itemIconEnd,
  commonIcon,
} from "./DropDownSettingsStyles";
import { ThemeContext } from "../../../../../core/providers/ThemeContext";
import { AuthContext } from "../../../../../core/providers/AuthContext";
import { TOAST_CONTAINER_LAYOUT } from "../../../../../util/Constants";
import LeaveWorkConfirmationDialog from "../../../../../components/dialogs/leaveWork/LeaveWorkConfirmationDialog";
import { refreshPage } from "../../../../../util/util-io";
import { NotificationsContext } from "../../../../../core/providers/NotificationsContext";
import ChangePassDialog from "../../../../../components/dialogs/changePassDialog/ChangePassDialog";
import { encryptPassword } from "../../../../../util/UtilFormat";
import { isNil } from "lodash";
import useRegionalConfig from "../../../../../core/hooks/useRegionalConfig";
import LanguageSelector from "./LanguageSelector";
import ChangeTelephoneNumberDialog from "../../../../../solutions/notifications/components/changeTelephoneNumberDialog/ChangeTelephoneNumberDialog";

function DropDownSettings({ setOpenLogoutConfirmation, handleComboCompany }) {
  const { auth } = useContext(AuthContext);
  const { mustChangePass, isLoginMad } = auth;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (mustChangePass && !isNil(isLoginMad) && isLoginMad === false) {
      setOpen(true);
    }
  }, [mustChangePass, isLoginMad]);

  function toggleDropDown(open) {
    setOpen(open);
  }

  return (
    <Navbar>
      <NavItem open={open} toggleDropDown={toggleDropDown}>
        <DropdownMenu
          toggleDropDown={toggleDropDown}
          setOpenLogoutConfirmation={setOpenLogoutConfirmation}
          handleComboCompany={handleComboCompany}
          mustChangePass={mustChangePass}
        />
      </NavItem>
    </Navbar>
  );
}

function Navbar(props) {
  const classes = useStyles();
  return (
    <nav>
      <ul className={classes.navBar}>{props.children}</ul>
    </nav>
  );
}

function NavItem({ open, toggleDropDown, children }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={() => toggleDropDown(false)}>
      <li className={classes.navItem}>
        <Tooltip title={t("GENERAL_CONFIG")}>
          <IconButton
            className={open ? classes.dropdownOpened : classes.dropdownClosed}
            onClick={() => toggleDropDown(!open)}
            style={{ padding: 7, marginRight: 10 }}
          >
            <Icon className={"fas fa-cog"} style={commonIcon} />
          </IconButton>
        </Tooltip>

        {open && children}
      </li>
    </ClickAwayListener>
  );
}

function DropdownMenu(props) {
  const {
    setOpenLogoutConfirmation,
    handleComboCompany,
    toggleDropDown,
    mustChangePass,
  } = props;
  const classes = useStyles();
  const muiTheme = useTheme();
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const { auth, changePassword } = useContext(AuthContext);
  const { companies, isLoginMad } = auth;

  const [activeMenu, setActiveMenu] = useState("main");

  const [openLeaveWorkDialogClient, setOpenLeaveWorkDialogClient] = useState({
    open: false,
    newCompany: null,
  });

  const { decimalSeparator, timeOffset } = useRegionalConfig();

  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(mustChangePass);

  const [openChangePhoneDialog, setOpenChangePhoneDialog] = useState(false);

  const { toggleTheme, isLightMode } = useContext(ThemeContext);

  const { handleNotificationsDock, newVersion } =
    useContext(NotificationsContext);

  function handleOpenLeaveWorkDialogClient(newCompany) {
    setOpenLeaveWorkDialogClient({ open: true, newCompany });
  }

  async function handleOpenLeaveWorkDialogClientDecision(execute) {
    setOpenLeaveWorkDialogClient({ open: false, newCompany: null });
    if (
      execute &&
      openLeaveWorkDialogClient &&
      openLeaveWorkDialogClient?.newCompany
    ) {
      //call change client
      await handleComboCompany(openLeaveWorkDialogClient?.newCompany);
      toggleDropDown(false);
      refreshPage();
    }
  }

  function handleToogleTheme() {
    toggleTheme();
    toggleDropDown(false);
  }

  async function handleChangePass({ oldP, newP, close }) {
    let toastContent = null;
    if (close) {
      setOpenChangePasswordDialog(false);
    } else {
      const response = await changePassword({
        oldP: encryptPassword(oldP),
        newP: encryptPassword(newP),
        isAutoChange: mustChangePass,
      });
      if (response) {
        const { ok, errorMsg: msg } = response;
        if (!ok) {
          toastContent = msg;
        } else {
          setOpenChangePasswordDialog(false);
          if (mustChangePass) {
            toggleDropDown(false);
          }
        }
      } else {
        toastContent = t("ERROR_RESOURCE_NOT_FOUND_TEXT");
      }
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
      });
    }
  }

  const handleCloseTelephoneNumberDialog = () => {
    setOpenChangePhoneDialog(false);
  };

  return (
    <div className={classes.dropDown} ref={dropdownRef}>
      {openLeaveWorkDialogClient && openLeaveWorkDialogClient?.open && (
        <LeaveWorkConfirmationDialog
          open={openLeaveWorkDialogClient?.open}
          handleConf={handleOpenLeaveWorkDialogClientDecision}
        />
      )}

      {openChangePasswordDialog && (
        <ChangePassDialog
          open={openChangePasswordDialog}
          handleChangePass={handleChangePass}
        />
      )}

      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
      >
        <div className="menu">
          {companies && companies.length > 1 && (
            <Button
              className={classes.itemDiv}
              onClick={() => setActiveMenu("companies")}
            >
              <div className={classes.iconAndText}>
                <Icon
                  className="fas fa-laptop-house"
                  style={{ ...itemIconStart, marginRight: 5 }}
                />
                <span className={classes.itemLabel}>
                  {t("CLIENT_COMBO_LABEL")}
                </span>
              </div>
              <Icon
                className="fas fa-angle-right"
                style={{ ...itemIconEnd, color: muiTheme.palette.text.primary }}
              />
            </Button>
          )}

          <Button
            className={classes.itemDiv}
            onClick={() => setActiveMenu("langs")}
          >
            <div className={classes.iconAndText}>
              <Icon className="fas fa-flag" style={itemIconStart} />
              <span className={classes.itemLabel}>
                {t("DD_SETTINGS_LANGS")}
              </span>
            </div>
            <Icon
              className="fas fa-angle-right"
              style={{ ...itemIconEnd, color: muiTheme.palette.text.primary }}
            />
          </Button>

          <div className={classes.itemDiv}>
            <div className={classes.iconAndText}>
              <Icon
                className="fas fa-moon"
                style={{
                  ...itemIconStart,
                  color: muiTheme.palette.text.primary,
                }}
              />
              <span className={classes.itemLabel}>
                {t("DD_SETTINGS_DARK_THEME")}
              </span>
            </div>
            <Switch onChange={handleToogleTheme} checked={!isLightMode} />
          </div>

          {/* Change Password Toggler */}
          {!isLoginMad && (
            <Button
              className={classes.itemButton}
              onClick={() => {
                setOpenChangePasswordDialog(true);
              }}
            >
              <Icon
                className="fas fa-key"
                style={{
                  ...itemIconStart,
                  color: muiTheme.palette.text.primary,
                }}
              />
              <span className={classes.itemLabel}>
                {t("CHANGE_PASS_DIALOG_TITLE")}
              </span>
            </Button>
          )}

          <Button
            className={classes.itemButton}
            onClick={() =>
              handleNotificationsDock({ open: true, isFromConfig: true })
            }
          >
            {newVersion && newVersion !== "" ? (
              <Badge badgeContent={1} classes={{ badge: classes.customBadge }}>
                <Icon className="fas fa-bell" />
              </Badge>
            ) : (
              <Icon
                className="fas fa-bell"
                style={{
                  ...itemIconStart,
                  color: muiTheme.palette.text.primary,
                }}
              />
            )}

            <span className={classes.itemLabel}>
              {t("NOTIFICATIONS_TITLE")}
            </span>
          </Button>

          <Button
            className={classes.itemDiv}
            onClick={() => setOpenChangePhoneDialog(true)}
          >
            <div className={classes.iconAndText}>
              <Icon className="fas fa-phone-square-alt" style={itemIconStart} />
              <span className={classes.itemLabel}>
                {t("CHANGE_TELEPHONE_TITLE")}
              </span>
            </div>
          </Button>

          {openChangePhoneDialog ? (
            <ChangeTelephoneNumberDialog
              open={openChangePhoneDialog}
              handleClose={handleCloseTelephoneNumberDialog}
              isOpenDirectly={true}
            />
          ) : null}

          {/* <Button
            className={classes.itemDiv}
            onClick={() => setActiveMenu("regional-config")}
          >
            <div className={classes.iconAndText}>
              <Icon className="fas fa-globe" style={itemIconStart} />
              <span className={classes.itemLabel}>
                {t("REGIONAL_CONFIG_TITLE")}
              </span>
            </div>
            <Icon
              className="fas fa-angle-right"
              style={{ ...itemIconEnd, color: muiTheme.palette.text.primary }}
            />
          </Button> */}

          <Hidden smUp>
            <Button
              className={classes.itemButton}
              onClick={() => {
                setOpenLogoutConfirmation(true);
              }}
            >
              <Icon
                className="fas fa-sign-out-alt"
                style={{
                  ...itemIconStart,
                  color: muiTheme.palette.text.primary,
                }}
              />
              <span className={classes.itemLabel}>{t("TOOLTIP_LOGOUT")}</span>
            </Button>
          </Hidden>
        </div>
      </CSSTransition>

      {/* LANGUAGES */}
      <CSSTransition
        in={activeMenu === "langs"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
      >
        <div className={classes.menu}>
          <Button
            className={classes.itemButton}
            onClick={() => setActiveMenu("main")}
          >
            <Icon
              className="fas fa-angle-left"
              style={{ ...itemIconStart, color: muiTheme.palette.text.primary }}
            />
            <h3 className={classes.itemLabel}>{t("DD_SETTINGS_LANGS")}</h3>
          </Button>

          <LanguageSelector toggleDropDown={toggleDropDown} />
        </div>
      </CSSTransition>

      {/* COMPANIES */}
      <CSSTransition
        in={activeMenu === "companies"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
      >
        <div className="menu">
          <Button
            className={classes.itemButton}
            onClick={() => setActiveMenu("main")}
          >
            <Icon
              className="fas fa-angle-left"
              style={{ ...itemIconStart, color: muiTheme.palette.text.primary }}
            />
            <h3 className={classes.itemLabel}>{t("CLIENT_COMBO_LABEL")}</h3>
          </Button>

          {companies.map((comp) => {
            return (
              <div key={comp.codCompany} className={classes.itemDiv}>
                <div className={classes.iconAndText}>{comp.name}</div>
                <Radio
                  key={`radio_${comp.codCompany}`}
                  checked={
                    auth &&
                    auth.company &&
                    auth.company.codCompany &&
                    auth.company.codCompany === comp.codCompany
                  }
                  onChange={() => {
                    handleOpenLeaveWorkDialogClient({
                      target: {
                        value: comp.codCompany,
                      },
                    });
                  }}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            );
          })}
        </div>
      </CSSTransition>

      {/* REGIONAL CONFIGURATION */}
      <CSSTransition
        in={activeMenu === "regional-config"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
      >
        <div className="menu">
          <Button
            className={classes.itemButton}
            onClick={() => setActiveMenu("main")}
          >
            <Icon
              className="fas fa-angle-left"
              style={{ ...itemIconStart, color: muiTheme.palette.text.primary }}
            />
            <h3 className={classes.itemLabel}>{t("REGIONAL_CONFIG_TITLE")}</h3>
          </Button>

          <h3 className={classes.itemLabel}>
            {t("REGIONAL_CONFIG_SEPARATOR_DECIMALS")}{" "}
            <strong>{decimalSeparator}</strong>
          </h3>
          <h3 className={classes.itemLabel}>
            Locale <strong>{timeOffset}</strong>
          </h3>
        </div>
      </CSSTransition>
    </div>
  );
}

export default DropDownSettings;
