import React from "react";
import { Divider, useMediaQuery, useTheme } from "@material-ui/core";

import HomeTabButton from "./HomeTabButton";
import InboxTabButton from "./InboxTabButton";
import WorkspaceButton from "./WorkspaceButton";
import DocsButton from "./DocsButton";

import { useStyles } from "./styles";
import { NEED_ENGINE_ON } from "../../../../util/Constants";
import ProcessesButton from "./ProcessesButton";
import FavsButton from "./FavsButton";
import EmployeeStoreButton from "./EmployeeStoreButton";

function EnvBtnDivider() {
  const classes = useStyles();

  return (
    <Divider orientation="vertical" flexItem className={classes.divider} />
  );
}
export default function EnvironmentButtons({
  isMobile,
  handleTabClick,
  activeTab,
  handleProcessClick,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div
      className={
        isMobile ? classes.environmentBtnMobile : classes.environmentBtnDesktop
      }
    >
      <HomeTabButton activeTab={activeTab} handleTabClick={handleTabClick} />
      <EnvBtnDivider />
      {NEED_ENGINE_ON && (
        <>
          <InboxTabButton
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <EnvBtnDivider />
        </>
      )}
      <WorkspaceButton activeTab={activeTab} handleTabClick={handleTabClick} />
      {!isMobile && (
        <>
          <EnvBtnDivider />
          <DocsButton activeTab={activeTab} handleTabClick={handleTabClick} />
        </>
      )}
      {isMobile && (
        <>
          <EnvBtnDivider />
          <ProcessesButton
            isMobile={isMobile}
            handleProcessClick={handleProcessClick}
          />
          <EnvBtnDivider />
          <FavsButton
            isMobile={isMobile}
            handleProcessClick={handleProcessClick}
          />
        </>
      )}

      {isMobile || isSmUp ? (
        <>
          <EnvBtnDivider />
          <EmployeeStoreButton />
        </>
      ) : null}
    </div>
  );
}
