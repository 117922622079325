import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { HEADER_HEIGHT } from "./header/EmployeeStoreHeader";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: "#ffffff",
    backgroundImage:
      "repeating-radial-gradient(circle at 0 0, transparent 0, #ffffff 150px), repeating-linear-gradient(#0b9cd705, #0b9cd707, #0b9cd709)",
  },
  applyOverflow: {
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
      overflowY: "auto",
    },
  },
}));

export default function EmployeeStorePageWrapper({ children, applyOverflow }) {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.pageContainer} ${
        applyOverflow ? classes.applyOverflow : ""
      }`}
    >
      {children}
    </Box>
  );
}
