import { useMemo } from "react";
import useCheckout from "./useCheckout";
import useSimulation from "./useSimulation";
import useCart from "./useCart";
import { isNil } from "lodash";
import useInfo from "./useInfo";

class EmsButtonsDisablesState {
  constructor() {
    this.isDisabled = false;
    this.disabledText = "";
  }
}

const useEmsButtons = ({
  productHasPrice,
  productHasStock,
  productHasEnoughStock,
  insideCart,
}) => {
  const { checkoutStep } = useCheckout();
  const { isSimulating } = useSimulation();
  const { loadingCart, emsCartId } = useCart();
  const { initialInfo } = useInfo();

  const buttonsDisabled = useMemo(() => {
    const state = new EmsButtonsDisablesState();

    if (initialInfo?.isEmsClosed === true) {
      state.isDisabled = true;
      state.disabledText = "EMPLOYEE_STORE.DISABLED_ACTION_BTNS_CLOSED";
      return state;
    }

    if (loadingCart) {
      state.isDisabled = true;
      state.disabledText = "EMPLOYEE_STORE.DISABLED_ACTION_BTNS_CART_LOADING";
      return state;
    }

    if (isSimulating) {
      state.isDisabled = true;
      state.disabledText = "EMPLOYEE_STORE.DISABLED_ACTION_BTNS_SIMULATING";
      return state;
    }

    if (!productHasPrice && !insideCart) {
      state.isDisabled = true;
      state.disabledText = "EMPLOYEE_STORE.DISABLED_ACTION_BTNS_NO_PRICE";
      return state;
    }

    if (!productHasStock && !insideCart) {
      state.isDisabled = true;
      state.disabledText = "EMPLOYEE_STORE.DISABLED_ACTION_BTNS_NO_STOCK";
      return state;
    }

    if (!productHasEnoughStock && !insideCart) {
      state.isDisabled = true;
      state.disabledText =
        "EMPLOYEE_STORE.DISABLED_ACTION_BTNS_NOT_ENOUGH_STOCK";
      return state;
    }

    if (checkoutStep > 0 && !isNil(emsCartId)) {
      state.isDisabled = true;
      state.disabledText = "EMPLOYEE_STORE.DISABLED_ACTION_BTNS_CHECKOUT";
      return state;
    }

    return state;
  }, [
    checkoutStep,
    isSimulating,
    loadingCart,
    productHasPrice,
    productHasStock,
    productHasEnoughStock,
    initialInfo,
    insideCart,
    emsCartId
  ]);

  return buttonsDisabled;
};

export default useEmsButtons;
