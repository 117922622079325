import React, { useMemo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EmployeeStorePageWrapper from "../layout/EmployeeStorePageWrapper";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import ShoppingCartItem from "../components/shopping-cart/ShoppingCartItem";
import ShoppingCartOrderSummary from "../components/shopping-cart/ShoppingCartOrderSummary";

import useCart from "../store/hooks/useCart";
import { filter, isEmpty, isNil, size, toLower, toString } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EMPLOYEE_STORE_HOME_ROUTE } from "../routes/EmployeeStoreRouter";
import useSimulation from "../store/hooks/useSimulation";
import useCheckout from "../store/hooks/useCheckout";
import { useTranslation } from "react-i18next";
import SimulationErrorDialog from "../components/simulation/SimulationErrorDialog";
import { Autocomplete } from "@material-ui/lab";
import ProductQuickViewModal from "../components/ProductQuickViewModal";
import useEmployeeStoreState from "../store/EmployeeStoreState";
import TooltipDisablesEmsBtns from "../components/TooltipDisablesEmsBtns";
import useInfo from "../store/hooks/useInfo";
import useEmsButtons from "../store/hooks/useEmsButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowY: "auto",
  },
  emptyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 30,
    width: "100%",
    height: "100%",
    padding: 16,
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  topActionsBox: {
    gap: "6px",
    display: "flex",
    padding: "8px",
    alignItems: "center",
    marginBottom: "8px",
    justifyContent: "flex-end",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },
  gridColumnReverse: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
}));

const emptyCartIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
  fontSize: 120,
};

function filterCatItemsOptions(options, state) {
  if (isNil(options) || isNil(state)) {
    return options;
  }

  const input = toLower(state.inputValue);

  return options.filter((option) => {
    return (
      toLower(toString(option?.ean)).includes(input) ||
      toLower(toString(option?.title)).includes(input)
    );
  });
}

export default function EmployeeStoreShoppingCartPage() {
  const classes = useStyles();
  const { loadingCart, cartItems, handleClearCart } = useCart();
  const { showSimulationDetails, toggleSimulationDetails, isSimulating } =
    useSimulation();
  const { checkoutStep } = useCheckout();
  let history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const { selectedProduct, closeQuickView } = useEmployeeStoreState();
  const { initialInfo } = useInfo();

  const buttonsDisabled = useEmsButtons({
    checkoutStep,
    isSimulating,
    initialInfo,
    insideCart: true,
    loadingCart,
  });

  const hasCartItems = !isNil(cartItems) && size(cartItems) > 0;

  const filteredItems = useMemo(() => {
    if (!isNil(value) && !isEmpty(value) && hasCartItems) {
      return filter(cartItems, function (o) {
        return !isNil(o) && o?.ean === value?.ean;
      });
    }

    return cartItems;
  }, [hasCartItems, cartItems, value]);

  return (
    <EmployeeStorePageWrapper>
      {loadingCart ? (
        <div className={classes.emptyContainer}>
          <CircularProgress size={72} />
        </div>
      ) : isEmpty(cartItems) ? (
        <div className={classes.emptyContainer}>
          <Icon className="fas fa-cart-plus" style={emptyCartIcon} />
          <Typography variant="h4" align="center">
            ¡Aún no tienes nada en tu carrito! Añade{" "}
            <Link
              onClick={() => history.push(EMPLOYEE_STORE_HOME_ROUTE)}
              className={classes.link}
            >
              productos
            </Link>{" "}
            para verlos aquí
          </Typography>
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={3} className={classes.gridColumnReverse}>
            <Grid item xs={12} md={8}>
              <Box className={classes.topActionsBox}>
                {checkoutStep > 0 ? (
                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={
                      showSimulationDetails ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )
                    }
                    onClick={toggleSimulationDetails}
                  >
                    {showSimulationDetails
                      ? "Ocultar detalles simulación"
                      : "Mostrar detalles simulación"}
                  </Button>
                ) : null}

                <TooltipDisablesEmsBtns
                  key="empty-cart-btn-tooltip"
                  buttonsDisabled={buttonsDisabled}
                >
                  <span>
                    <Button
                      variant="outlined"
                      size="large"
                      startIcon={<DeleteSweepIcon />}
                      onClick={handleClearCart}
                      disabled={buttonsDisabled?.isDisabled}
                    >
                      {t("EMPLOYEE_STORE.EMPTYING_CART")}
                    </Button>
                  </span>
                </TooltipDisablesEmsBtns>

                {hasCartItems ? (
                  <Autocomplete
                    openOnFocus
                    autoHighlight
                    id="search-cart-items-auto-cmplete"
                    options={cartItems}
                    getOptionLabel={(option) =>
                      `${option?.title} (${option?.ean})`
                    }
                    style={{ width: isSmDown ? "100%" : 310, marginBottom: 4 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("HEADER_SEARCH_PLACEHOLDER")}
                        variant="outlined"
                        margin="dense"
                      />
                    )}
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    filterOptions={(options, state) =>
                      filterCatItemsOptions(options, state)
                    }
                  />
                ) : null}
              </Box>
              {filteredItems.map((item) => (
                <ShoppingCartItem key={item.ean} {...item} />
              ))}
            </Grid>
            <Grid item xs={12} md={4}>
              <ShoppingCartOrderSummary />
            </Grid>
          </Grid>
        </div>
      )}
      <SimulationErrorDialog />
      <ProductQuickViewModal
        product={selectedProduct}
        open={Boolean(selectedProduct)}
        onClose={closeQuickView}
      />
    </EmployeeStorePageWrapper>
  );
}
