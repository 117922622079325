import { Box, Icon, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { formatPriceWithCountry } from "../util/util-ems";
import { useTranslation } from "react-i18next";
import { toNumber, toSafeInteger } from "lodash";
import { AuthContext } from "../../../core/providers/AuthContext";
import useInfo from "../store/hooks/useInfo";
import FilterAdminBadge from "./filters/FilterAdminBadge";

const useStyles = makeStyles((theme) => ({
  boxContainerPriceStock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 12,
  },
  typoNo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "max-content",
    padding: "0px 6px",
    color: "#FFF",
    fontWeight: "bold",
    borderRadius: "10px",
    marginTop: "4px",
    gap: 6,
  },
  typoNoPrice: {
    backgroundColor: `${theme.palette.semaphore.orange}CC`,
  },
  typoNoStock: {
    backgroundColor: `${theme.palette.semaphore.red}CC`,
  },
  bold: {
    fontWeight: "bold",
  },
  stockAdmin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
  },
  priceText: {
    fontSize: "1rem",
    fontWeight: 100,
  },
}));

export default function ProductPriceAndStock({ price, stock, minQuantity }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { auth } = useContext(AuthContext);

  const { initialInfo } = useInfo();
  const isEmsAdmin = initialInfo?.isEmsAdmin === true;

  const productHasPrice = toNumber(price) > 0;
  const productHasStock = toNumber(stock) > 0;
  const productHasEnoughStock =
    productHasStock && toNumber(stock) >= toNumber(minQuantity);

  const needToShowNoStockBadge = !productHasStock || !productHasEnoughStock;

  return (
    <Box className={classes.boxContainerPriceStock}>
      {productHasPrice ? (
        <Typography variant="h5" className={classes.bold}>
          {formatPriceWithCountry({ price, country: auth?.emsCountry })}{" "}
          <span className={classes.priceText}>
            {t("EMPLOYEE_STORE.PRODUCT_UNIT_PRICE")}{" "}
          </span>
        </Typography>
      ) : (
        <Typography
          variant="h5"
          className={`${classes.typoNo} ${classes.typoNoPrice}`}
        >
          <Icon className="fas fa-hand-holding-usd" />
          {t("EMPLOYEE_STORE.PRODUCT_HAS_NOT_PRICE")}
        </Typography>
      )}

      {needToShowNoStockBadge ? (
        <Typography
          variant="h5"
          className={`${classes.typoNo} ${classes.typoNoStock}`}
        >
          <Icon className="fas fa-boxes" />
          {t("EMPLOYEE_STORE.PRODUCT_HAS_NOT_STOCK")}
        </Typography>
      ) : null}

      {isEmsAdmin ? (
        <Box className={classes.stockAdmin}>
          <Typography variant="h6">Stock ({toSafeInteger(stock)})</Typography>
          <FilterAdminBadge showOnlyAdmin={true} />
        </Box>
      ) : null}
    </Box>
  );
}
