import { isEqual, isMap, isNil } from "lodash";

const INITIAL_STATE = {
  fenixStoredFilters: null,
  fenixStoredCollapsed: false,
  fenixStoredShowBpmDialog: false,
  isEditingFenixForm: false,
  fenixStoredBreadcumbRoutes: new Map(),
};

export const createFenixSlice = (set, get) => ({
  ...INITIAL_STATE,
  setFenixStoredFilters: (newState) =>
    set(() => ({ fenixStoredFilters: newState })),
  setFenixStoredCollapsed: (newState) =>
    set(() => ({ fenixStoredCollapsed: newState })),
  setFenixStoredShowBpmDialog: (newState) =>
    set(() => ({ fenixStoredShowBpmDialog: newState })),
  addFenixBreadcumbRoute: (newRoute) =>
    set((state) => ({
      fenixStoredBreadcumbRoutes: state.fenixStoredBreadcumbRoutes.set(newRoute, {
        fenixStoredFilters: state.fenixStoredFilters,
        fenixStoredCollapsed: state.fenixStoredCollapsed,
        fenixStoredShowBpmDialog: state.fenixStoredShowBpmDialog,
      }),
    })),
  removeFenixBreadcumbRoute: (routeToDelete, onlyWithFid) => {
    const actualMap = get().fenixStoredBreadcumbRoutes;
    if (!isNil(routeToDelete) && isMap(actualMap)) {
      if (onlyWithFid) {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(([key, value]) => {
            const shoudlReturn =
              !isEqual(key, routeToDelete) && isNil(value?.formInstanceId);
            return shoudlReturn;
          })
        );
        set(() => ({
          fenixStoredBreadcumbRoutes: newMapToSet,
        }));
      } else {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(
            ([key]) => !key.startsWith(routeToDelete)
          )
        );
        set(() => ({
          fenixStoredBreadcumbRoutes: newMapToSet,
        }));
      }
    }
  },
  updateFenixBreadcumbRoute: (route, newParams) => {
    const actualBC = get().fenixStoredBreadcumbRoutes;
    const spreadBC = actualBC.has(route) ? { ...actualBC.get(route) } : {};

    set((state) => ({
      fenixStoredBreadcumbRoutes: state.fenixStoredBreadcumbRoutes.set(route, {
        ...spreadBC,
        ...newParams,
      }),
    }));
  },
  toggleIsEditingFenixForm: () => {
    set((state) => ({ isEditingFenixForm: !state.isEditingFenixForm }));
  },
  clear: () => set(INITIAL_STATE),
});
