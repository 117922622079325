import React from "react";
import {
  Box,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DSuiteLogoNew from "../solutions/login/components/DSuiteLogoNew";
import { useTranslation } from "react-i18next";
import { useLiveClock } from "../core/hooks/useLiveClock";

// Estilos personalizados
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: 'url("/assets/img/login/dsuite-login-back.webp")',
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundColor: "#0A0335",
  },
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: "rgb(255 255 255 / 70%)",
    borderRadius: "16px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(10px)",
    WebkitBackdropFilter: "blur(10px)",
    border: "5px solid rgb(35 93 159 / 30%)",
    textAlign: "center",
    maxWidth: "90%",
    [theme.breakpoints.down("md")]: {
      padding: 6,
    },
  },
}));

const MaintenanceFallback = ({ eta }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { timeOffset } = useLiveClock();

  return (
    <Box className={classes.root}>
      <Box className={classes.mainBox}>
        <DSuiteLogoNew maxWidth={isMdDown ? 300 : 500} />
        <Typography variant="h2" className={classes.title}>
          {t("MAINTENANCE_TITLE")}
        </Typography>
        <Typography variant="h5">
          {`${t("MAINTENANCE_SUBTITLE", { eta, timeOffset })}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default MaintenanceFallback;
