import React, { useState, useEffect, useRef } from "react";
import { TimePicker } from "@material-ui/pickers";
import * as _ from "lodash";
import { format, isValid, parse } from "date-fns";

import {
  applyPreservedEvent,
  defineInputEnabledByOperation,
  getCompletePath,
  preserveFormEvent,
} from "../../../util/UtilForm";
import { useTranslation } from "react-i18next";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import { TIME_PATTERN } from "../../../util/Constants";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
import { useLastWasTab } from "../../../core/hooks/useLastWasTab";

export default function SimpleTimePickerField(props) {
  //#region [Definitions]
  const { idSchema, schema, formData, formContext } = props;
  //Destr important attrs
  const { value, presentationInfo: pi, isSavedEntity } = formData || {};
  const [selectedDate, setSelectedDate] = useState(null);
  const [isEnabled, setIsEnabled] = useState(true);
  const { t } = useTranslation();
  const {
    operation,
    section,
    block,
    formInstanceId,
    notifySetValueToServer,
    errorMap,
    pre,
    isBlockDisabledByWizard,
    shadowStatus
  } = formContext;
  const timePickerRef = useRef(null);
  const [presentationInfo, setPresentationInfo] = useState(null);
  //#endregion

  const formatToApply =
    schema && schema.dateFormat ? schema.dateFormat : TIME_PATTERN;

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema,
    operation,
    isSavedEntity,
  });

  const { lastWasTab, handleKeyDown } = useLastWasTab();

  //Clearing function
  useEffect(() => {
    if (!_.isNil(value) && value !== "") {
      const realDate = parse(value, TIME_PATTERN, new Date());

      if (!_.isNil(realDate) && isValid(realDate)) {
        setSelectedDate(realDate);
      } else {
        const parsedDate = parse(value, TIME_PATTERN, new Date());
        if (!_.isNil(parsedDate) && isValid(parsedDate)) {
          setSelectedDate(parsedDate);
        } else {
          setSelectedDate(null);
        }
      }
    } else {
      setSelectedDate(null);
    }
  }, [value]);

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      schema?.canEdit,
      schema?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }

    // const isThisFieldOnErrorMap = errorMap.some(
    //   (error) => error["key"] === completeIdControl
    // );
    // const isThisFieldTheError =
    //   errorMap && errorMap.length > 0 && isThisFieldOnErrorMap;
    // if (errorMap && errorMap.length > 0 && !isThisFieldTheError) {
    //   setIsEnabled(false);
    // }
  }, [
    operation,
    schema,
    //errorMap,
    completeIdControl,
  ]);

  const handleChange = async (value, toSend, e) => {
    const preservedEvent = preserveFormEvent({ event: e, lastWasTab, formInstanceId });

    //First change de control
    setSelectedDate(value);
    if (toSend) {
      //For form context, format date to schema definition
      if (isValid(value)) {
        const formattedDate = format(value, TIME_PATTERN);
        //Line always 0, its head field
        await notifySetValueToServer(
          formInstanceId,
          completeIdControl,
          formattedDate,
          0,
          null
        );
      } else {
        await notifySetValueToServer(
          formInstanceId,
          completeIdControl,
          value,
          0,
          null
        );
      }
      applyPreservedEvent({ preservedEvent });
    }
  };

  //Input styles
  const controlStyles = {
    base: {
      width: "100%",
      backgroundColor:
        presentationInfo &&
        presentationInfo.backgroundColor &&
        presentationInfo.backgroundColor,
    },
    inner: {
      color:
        presentationInfo && presentationInfo.color && presentationInfo.color,
      fontSize:
        presentationInfo &&
        presentationInfo.fontSize &&
        presentationInfo.fontSize,

      fontWeight:
        presentationInfo &&
        presentationInfo.fontWeight &&
        presentationInfo.fontWeight,
      fontStyle:
        presentationInfo &&
        presentationInfo.fontStyle &&
        presentationInfo.fontStyle,
    },
  };

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      presentationInfo={presentationInfo}
      shadowStatus={shadowStatus}
    >
      <TimePicker
        id={completeIdControl}
        disabled={
          !isEnabled ||
          (presentationInfo && presentationInfo?.enabled === false) ||
          disableDueChangeSavedValue ||
          isBlockDisabledByWizard === true
        }
        //clearable
        ampm={false}
        value={selectedDate}
        onChange={(date) => handleChange(date, false)}
        onBlur={(e) => handleChange(selectedDate, true, e)}
        onAccept={(date) => handleChange(date, true)}
        onKeyDown={handleKeyDown}
        //invalidDateMessage={t("INVALID_DATE")}
        invalidDateMessage=""
        inputRef={timePickerRef}
        formant={formatToApply}
        style={controlStyles.base}
        InputProps={{
          style: controlStyles.inner,
        }}
        //error={false}
        error={errorMap.some((e) => e && e.key === completeIdControl)}
        cancelLabel={t("DATE_PICKERS_CANCEL")}
        okLabel={t("DATE_PICKERS_OK")}
      />
    </SimpleFieldContainer>
  );
}
