import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { clone, includes, isNil, toLower } from "lodash";
import useIsMounted from "react-is-mounted-hook";
import { AVAILABLE_FORMATS } from "./formats";
import PDFViewer from "./viewers/PDFViewer";
import ImageViewer from "./viewers/ImageViewer";
import AudioViewer from "./viewers/AudioViewer";
import VideoViewer from "./viewers/VideoViewer";
import DocsViewer from "./viewers/DocsViewer";
//import * as base64 from "base-64";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  fileViewerContainer: {
    color: "inherit",
    backgroundColor: ({ bg }) => (bg && bg !== "" ? bg : "inherit"),
    height: "100%",
    width: "100%",
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
}));

export default function CustomFileViewer({
  fileType,
  filePath,
  bg,
  id,
  keyViewer: key,
  fileValue,
  isFieldPreview,
  isExternalDialog,
}) {
  const classes = useStyles({ bg });
  const isMounted = useIsMounted();
  const [generatedURL, setGeneratedURL] = useState("");
  const [generatedType, setGeneratedType] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    let blobURL = null;
    if (fileValue && fileValue.url && fileValue.url !== "") {
      blobURL = fileValue.url;
    } else if (fileValue) {
      const { name, bytes } = clone(fileValue);
      if (name && bytes) {
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(name)[1].toLowerCase();
        var bytesOfBlob = new Uint8Array(bytes); // pass your byte response to this constructor
        if (AVAILABLE_FORMATS.pdf.desc === ext) {
          blobURL = bytesOfBlob;
        } else {
          var blob = new Blob([bytesOfBlob], { type: ext });
          blobURL = window.URL.createObjectURL(blob);
          if (isMounted() && generatedType === "") {
            setGeneratedType(ext);
          }
        }
      }
    }

    if (blobURL !== null) {
      if (isMounted()) {
        setGeneratedURL(blobURL);
      }
    }

    // return () => {
    //   if (blobURL) {
    //     window.URL.revokeObjectURL(blobURL);
    //   }
    // };
    //eslint-disable-next-line
  }, [fileValue, id]);

  const filePathToSet =
    filePath && filePath !== ""
      ? filePath
      : generatedURL && generatedURL !== ""
      ? generatedURL
      : null;

  const fileTypeToSet =
    fileType && fileType !== ""
      ? fileType
      : generatedType && generatedType !== ""
      ? generatedType
      : null;

  const isEmsPath = includes(pathname, "/employee/store/");

  if (isNil(filePathToSet) || isNil(fileTypeToSet)) {
    return <div id={id} key={key} className={classes.fileViewerContainer} />;
  }

  return (
    <div
      className={classes.fileViewerContainer}
      style={{
        alignItems: isExternalDialog && "flex-start",
        padding: isExternalDialog && 0,
      }}
    >
      {fileTypeToSet.toLowerCase() === AVAILABLE_FORMATS.mp4.desc ? (
        <VideoViewer id={id} keySet={key} filePathToSet={filePathToSet} />
      ) : fileTypeToSet.toLowerCase() === AVAILABLE_FORMATS.mp3.desc ? (
        <AudioViewer id={id} keySet={key} filePathToSet={filePathToSet} />
      ) : fileTypeToSet.toLowerCase() === AVAILABLE_FORMATS.pdf.desc &&
        !isEmsPath ? (
        <PDFViewer
          filePath={filePathToSet}
          keySet={key}
          isFieldPreview={isFieldPreview}
          isExternalDialog={isExternalDialog}
        />
      ) : AVAILABLE_FORMATS.images.desc.includes(
          fileTypeToSet.toLowerCase()
        ) ? (
        <ImageViewer id={id} keySet={key} filePathToSet={filePathToSet} />
      ) : fileTypeToSet.toLowerCase() === AVAILABLE_FORMATS.docx.desc ||
        fileTypeToSet.toLowerCase() === AVAILABLE_FORMATS.doc.desc ||
        fileTypeToSet.toLowerCase() === AVAILABLE_FORMATS.ppt.desc ||
        fileTypeToSet.toLowerCase() === AVAILABLE_FORMATS.pptx.desc ||
        fileTypeToSet.toLowerCase() === AVAILABLE_FORMATS.xls.desc ||
        fileTypeToSet.toLowerCase() === AVAILABLE_FORMATS.xlsx.desc ||
        isEmsPath ? (
        <DocsViewer
          id={id}
          keySet={key}
          filePathToSet={filePathToSet}
          ext={toLower(fileTypeToSet)}
        />
      ) : null}
    </div>
  );
}
