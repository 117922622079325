import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import EmployeeStoreHeader from "./header/EmployeeStoreHeader";
import EmployeeStoreMain from "./EmployeeStoreMain";
import useInfo from "../store/hooks/useInfo";
import EmployeeStoreLayoutBlocksDialog from "./dialogs/EmployeeStoreLayoutBlocksDialog";
import EmployeeStoreLayoutEmsClosedDialog from "./dialogs/EmployeeStoreLayoutEmsClosedDialog";
import useFavorites from "../store/hooks/useFavorites";
import EmployeeStoreLayoutEmsNoActiveCatalogDialog from "./dialogs/EmployeeStoreLayoutEmsNoActiveCatalogDialog";
import EmployeeStoreLayoutEmsCountryMissmatchDialog from "./dialogs/EmployeeStoreLayoutEmsCountryMissmatchDialog";
import EmployeeStoreLayoutEmsClientMissmatchDialog from "./dialogs/EmployeeStoreLayoutEmsClientMissmatchDialog";
import EmployeeStoreLayoutUseSingleTabDialog from "./dialogs/EmployeeStoreLayoutUseSingleTabDialog";

const useStyles = makeStyles(() => ({
  layoutContainer: {
    backgroundColor: "#FFFFFF",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));

export default function EmployeeStoreLayout({ children, isHomePage }) {
  const classes = useStyles();
  const { handleFetchInitialInfo } = useInfo();
  const { getFavorites } = useFavorites();

  useEffect(() => {
    handleFetchInitialInfo();
    getFavorites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.layoutContainer}>
      <EmployeeStoreLayoutBlocksDialog />
      <EmployeeStoreLayoutEmsClosedDialog />
      <EmployeeStoreLayoutEmsNoActiveCatalogDialog />
      <EmployeeStoreLayoutEmsCountryMissmatchDialog />
      <EmployeeStoreLayoutEmsClientMissmatchDialog />
      <EmployeeStoreLayoutUseSingleTabDialog />
      <EmployeeStoreHeader isHomePage={isHomePage} />
      <EmployeeStoreMain isHomePage={isHomePage}>{children}</EmployeeStoreMain>
    </Box>
  );
}
