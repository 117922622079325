import { METHOD_GET, METHOD_PUT } from "../../../util/Constants";
import { BASE_EMS_URL, handleApiResponse } from "./base";

const GET_POLICY_ENDPOINT = "/info/get-policy";
const GET_CONTACT_INFO_ENDPOINT = "/info/get-contact";
const GET_INITIAL_INFO_ENDPOINT = "/info/get-initial-user-info";
const GET_PRE_CHECKOUT_INFO_ENDPOINT = "/info/pre-checkout";
const PUT_CHANGE_OPEN_EMS_BY_DEFAULT = "/info/change-open-ems-by-default";

export const fetchPolicyApi = async (headers, logout) => {
  const response = await fetch(`${BASE_EMS_URL}${GET_POLICY_ENDPOINT}`, {
    method: METHOD_GET,
    headers: headers,
  });

  return handleApiResponse(response, logout);
};

export const fetchInitialInfoApi = async (headers, logout) => {
  const response = await fetch(`${BASE_EMS_URL}${GET_INITIAL_INFO_ENDPOINT}`, {
    method: METHOD_GET,
    headers: headers,
  });

  return handleApiResponse(response, logout);
};

export const fetchPreCheckoutInfoApi = async (headers, logout, emsIdCompra) => {
  const response = await fetch(
    `${BASE_EMS_URL}${GET_PRE_CHECKOUT_INFO_ENDPOINT}?emsIdCompra=${emsIdCompra}`,
    {
      method: METHOD_GET,
      headers: headers,
    }
  );

  return handleApiResponse(response, logout);
};

export const fetchContactInfoApi = async (headers, logout) => {
  const response = await fetch(`${BASE_EMS_URL}${GET_CONTACT_INFO_ENDPOINT}`, {
    method: METHOD_GET,
    headers: headers,
  });

  return handleApiResponse(response, logout);
};

export const changeOpenEmsByDefaultApi = async (headers, logout, newValue) => {
  const valueToSet = newValue === true ? true : false;
  const response = await fetch(
    `${BASE_EMS_URL}${PUT_CHANGE_OPEN_EMS_BY_DEFAULT}?openEmsByDefault=${valueToSet}`,
    {
      method: METHOD_PUT,
      headers: headers,
    }
  );

  return handleApiResponse(response, logout);
};
