import React, { useContext } from "react";
import {
  Box,
  Icon,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ThemeContext } from "../../../../../core/providers/ThemeContext";
import { AuthContext } from "../../../../../core/providers/AuthContext";
import useGlobalEms from "../../../store/hooks/useGlobalEms";
import { APP_VERSION } from "../../../../../util/Constants";

const useStyles = makeStyles(() => ({
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  backLogo: {
    transform: "rotateY(180deg)",
    padding: 8,
    marginRight: 6
  },
  typoVersion: {
    color: "darkslategray",
    width: "52px",
    border: "1px solid lightgray",
    padding: "0px 7px",
    borderRadius: "50px",
    fontSize: "10px",
  },
}));

export default function EmployeeStoreBack() {
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();
  const { recoverThemeMode } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const { resetCompleteEmsStoreState } = useGlobalEms();

  const handleClick = () => {
    resetCompleteEmsStoreState();
    recoverThemeMode({ isEmployeeStore: false });
    history.push("/");
  };

  if (auth?.isOnlyEmsUser === true) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      <Tooltip title={t("EMPLOYEE_STORE.BACK_TO_DSUITE")}>
        <IconButton
          className={classes.backLogo}
          onClick={() => handleClick()}
          aria-label="back-to-dsuite-button"
        >
          <Icon className="fas fa-sign-out-alt" />
        </IconButton>
      </Tooltip>
      <Typography
        className={classes.typoVersion}
      >{`v${APP_VERSION}`}</Typography>
    </Box>
  );
}
