import React, { useState } from "react";
import {
  makeStyles,
  Popper,
  Typography,
  ClickAwayListener,
  CircularProgress,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import { Box, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useContactInfo from "../../../store/hooks/useContactInfo";
import { isEmpty, isNil } from "lodash";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles(() => ({
  popperContainer: {
    zIndex: 10,
    width: 275,
    maxWidth: 400,
    height: 200,
    display: "flex",
    padding: 10,
    margin: 10,
    flexDirection: "column",
    borderRadius: 5,
    alignItems: "center",
    backgroundColor: "#ffffff",
    boxShadow: "rgb(0 0 0 / 20%) 5px 2px 10px",
  },
  contactInfo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    overflow: "hidden",
    wordBreak: "break-word",
    textAlign: "center",
  },
  profileInfo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflow: "hidden",
    padding: 10,
  },
  title: {
    color: "#3c7ee0",
  },
  contentBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  contentBoxLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  fileList: {
    width: "100%",
    padding: 12,
  },
  btnDownload: {
    width: "100%",
  },
}));

export default function EmployeeStoreContactUs() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openContactPop, setOpenContactPop] = useState(false);
  const { t } = useTranslation();
  const { loading, contactInfo, fetchContactInfo } = useContactInfo();

  const handleBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenContactPop((prev) => !prev);
    fetchContactInfo();
  };

  const handleBtnCloseClick = () => {
    setOpenContactPop(false);
  };

  return (
    <ClickAwayListener onClickAway={handleBtnCloseClick}>
      <div>
        <Link onClick={handleBtnClick} component="button" variant="body2">
          {t("EMPLOYEE_STORE.CONTACT_US")}
        </Link>

        <Popper
          open={openContactPop}
          anchorEl={anchorEl}
          transition
          className={classes.popperContainer}
        >
          {loading ? (
            <Box className={classes.contentBoxLoading}>
              <CircularProgress />
            </Box>
          ) : (
            <Box className={classes.contentBox}>
              <Typography variant="h5" className={classes.title}>
                {t("EMPLOYEE_STORE.CONTACT_INFO")}
              </Typography>
              <Box margin={0.5} className={classes.profileInfo}>
                <Typography variant="body2" className={classes.contactInfo}>
                  {contactInfo?.emsCountryContactInfo}
                </Typography>
              </Box>

              {!isNil(contactInfo) &&
              !isNil(contactInfo?.extraContactInfoFiles) &&
              !isEmpty(contactInfo?.extraContactInfoFiles) ? (
                <List className={classes.fileList}>
                  <Typography>{""}</Typography>
                  {contactInfo.extraContactInfoFiles.map((file) => (
                    <ListItem key={file.key}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.btnDownload}
                        endIcon={<GetAppIcon />}
                        key={file.key}
                        href={file.url}
                        download
                      >
                        {t("EMPLOYEE_STORE.CONTACT_INFO_DOWNLOAD_MORE")}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              ) : null}
            </Box>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
