/* eslint-disable no-console */
import { msalInstance } from "../instance/msalInstance";
import { loginRequest, SSO_ID_TOKEN_STORAGE_PROP } from "../config/authConfig";
import { isEmpty, isNil, isNumber, toNumber, toString, trim } from "lodash";
import { parseToJsonIfPosible } from "../../../util/UtilFormat";
import { isJwtExpired } from "../../../util/UtilJwt";
import { StringBuilder } from "../../../util/StringBuilder";

export async function callMsGraph(params) {
  const { endpoint } = params;

  const account = msalInstance.getActiveAccount();
  if (account) {
    const accessToken = await getNewFreshTokenInvokingMsal({
      account,
      isForProfileImage: true,
    });

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(endpoint, options);
  }
}

export async function getMicrosoftAccessToken({
  account,
  retry = 0,
  currentSb,
}) {
  const sb = !isNil(currentSb) ? currentSb : new StringBuilder();
  if (retry === 0) {
    console.clear();
    sb.appendNewLine(
      "DSUITE - INIT RETRIEVE TOKEN CASCADE --------------------------------------------------------"
    );
  }

  // Early return if max retries reached
  if (toNumber(retry) > 2) {
    sb.appendNewLine(
      "DSUITE - Max retries reached while getting Microsoft token"
    );
    return null;
  }

  let tokenResult = null;

  try {
    // 1. Try to get fresh token first (most reliable source)
    const freshToken = await getNewFreshTokenInvokingMsal({ account });
    if (freshToken) {
      sb.appendNewLine(logTokenSource("fresh", retry, 1));
      tokenResult = freshToken;
      return tokenResult;
    } else {
      sb.appendNewLine(logNOSource("fresh", retry, 1));
    }

    // 2. Try to get token from storage (if exists and not expired)
    const storedToken = getTokenFromStorage(account);
    if (storedToken && !isJwtExpired({ token: storedToken })) {
      sb.appendNewLine(logTokenSource("storage", retry, 2));
      tokenResult = storedToken;
      return tokenResult;
    } else {
      sb.appendNewLine(logNOSource("storage", retry, 2));
    }

    // 3. Try to get token from account
    if (hasValidRawIdToken(account)) {
      const rawToken = trim(toString(account?.idToken));
      if (!isJwtExpired({ token: rawToken })) {
        sb.appendNewLine(logTokenSource("account", retry, 3));
        tokenResult = rawToken;
        return tokenResult;
      } else {
        sb.appendNewLine(`DSUITE -  (3) TOKEN EXPIRED. CONTINUES CASCADE`);
      }
    } else {
      sb.appendNewLine(logNOSource("account", retry, 3));
    }

    // 4. Try to get token from cache
    const cachedToken = msalInstance.getTokenCache({
      ...loginRequest,
      account: account,
    });
    if (cachedToken && !isJwtExpired({ token: cachedToken })) {
      sb.appendNewLine(logTokenSource("cache", retry, 4));
      tokenResult = cachedToken;
      return tokenResult;
    } else {
      sb.appendNewLine(logNOSource("account", retry, 4));
    }

    // 5. If all attempts fail, retry the process
    sb.appendNewLine(
      "DSUITE - All token acquisition attempts failed, retrying..."
    );
    return getMicrosoftAccessToken({
      account,
      retry: calculateRetry({ retry, currentSb: sb }),
    });
  } catch (error) {
    sb.appendNewLine("DSUITE - Error getting Microsoft token:", error);
    return getMicrosoftAccessToken({
      account,
      retry: calculateRetry({ retry, currentSb: sb }),
    });
  } finally {
    sb.appendNewLine(
      "DSUITE - FINISH RETRIEVE TOKEN CASCADE ------------------------------------------------------"
    );
    console.log(sb.toString());
    if (!isNil(tokenResult)) {
      window.localStorage.setItem(SSO_ID_TOKEN_STORAGE_PROP, tokenResult);
    }
  }
}

// Helper functions
export function getTokenFromStorage(account) {
  const propKeyFirstTry = `${account?.homeAccountId}-${account?.environment}-idtoken-${account?.idTokenClaims?.aud}-${account?.idTokenClaims?.tid}---`;
  const objectMsal = window.localStorage.getItem(propKeyFirstTry);
  const objectMsalJson = parseToJsonIfPosible(objectMsal);
  const secretMsalJson = objectMsalJson?.secret;

  return secretMsalJson && trim(toString(secretMsalJson)) !== ""
    ? secretMsalJson
    : window.localStorage.getItem(SSO_ID_TOKEN_STORAGE_PROP);
}

function hasValidRawIdToken(account) {
  return !isNil(account?.idToken) && trim(toString(account?.idToken)) !== "";
}

function logTokenSource(source, retry, step) {
  return `DSUITE -  (${step}) RETURNS ID TOKEN FROM ${source.toUpperCase()}. RETRY: [${retry}]`;
}

function logNOSource(source, retry, step) {
  return `DSUITE -  (${step}) NO TOKEN ADQUIRED FROM THIS STEP ${source.toUpperCase()}. RETRY: [${retry}]`;
}

function calculateRetry({ retry }) {
  const retryNum = toNumber(retry);
  if (isNil(retryNum) || isNaN(retryNum) || !isNumber(retryNum)) {
    return 1;
  }

  return retryNum + 1;
}

async function getNewFreshTokenInvokingMsal({ account, isForProfileImage }) {
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    if (!isNil(response) && !isEmpty(response) && response !== "") {
      return isForProfileImage === true
        ? response?.accessToken
        : response?.idToken;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
}
