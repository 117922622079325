import {
  changeOpenEmsByDefaultApi,
  fetchInitialInfoApi,
  fetchPreCheckoutInfoApi,
} from "../../api/infoApi";

const INITIAL_STATE = {
  // initial info
  loadingInfo: false,
  errorLoadingInfo: null,
  initialInfo: null,
  firstInitialInfoCompleted: false,
  showClosedEmsDialog: false,
  alreadyShownClosedEmsDialog: false,
  showDialogNoActiveCatalog: false,
  showErrorEmsCountryMissmatch: false,
  showErrorEmsClientMissmatch: false,
  amountMonth: null,

  // pre checkout dialog
  preCheckoutInfo: {
    showPreCheckoutConfirmationDialog: false,
    preCheckoutConfirmationDialogTitle: null,
    preCheckoutConfirmationDialogBodyItems: null,
    usePaymentSystem: true,
    paymentSystem: null,
  },
};

const createInfoSlice = (set, get) => ({
  ...INITIAL_STATE,

  fetchInitialInfo: async (headers, logout) => {
    set({ loadingInfo: true, errorLoadingInfo: null });
    try {
      const { data, error } = await fetchInitialInfoApi(headers, logout);
      if (data) {
        set({
          initialInfo: data,
          loadingInfo: false,
          firstInitialInfoCompleted: true,
          showClosedEmsDialog: data?.isEmsClosed,
          showErrorEmsCountryMissmatch: data?.showErrorEmsCountryMissmatch,
          showErrorEmsClientMissmatch: data?.showErrorEmsClientMissmatch,
          amountMonth: data?.amountMonth,
        });
      } else {
        set({
          errorLoadingInfo: error,
          loadingInfo: false,
          firstInitialInfoCompleted: true,
        });
      }
    } catch (error) {
      set({
        errorLoadingInfo: error.message,
        loadingInfo: false,
        firstInitialInfoCompleted: true,
      });
    }
  },

  openClosedEmsDialog: () => {
    set({ showClosedEmsDialog: true, alreadyShownClosedEmsDialog: false });
  },
  closeClosedEmsDialog: () => {
    set({ showClosedEmsDialog: false, alreadyShownClosedEmsDialog: true });
  },

  openNoActiveCatalogDialog: () => {
    set({ showDialogNoActiveCatalog: true });
  },
  closeNoActiveCatalogDialog: () => {
    set({ showDialogNoActiveCatalog: false });
  },
  fetchPreCheckoutInfo: async (headers, logout) => {
    try {
      const { data } = await fetchPreCheckoutInfoApi(headers, logout, get().emsCartId);
      if (data) {
        set({
          preCheckoutInfo: data,
        });
        return data;
      } else {
        set({
          preCheckoutInfo: INITIAL_STATE.preCheckoutInfo,
        });
        return null;
      }
    } catch (error) {
      set({
        preCheckoutInfo: INITIAL_STATE.preCheckoutInfo,
      });
      return null;
    }
  },
  changeOpenEmsByDefault: async (headers, logout, newValue) => {
    try {
      const response = await changeOpenEmsByDefaultApi(
        headers,
        logout,
        newValue
      );
      if (response?.ok === true) {
        const valueToSet = newValue === true ? true : false;
        set((state) => ({
          initialInfo: {
            ...state.initialInfo,
            openEmsByDefault: valueToSet,
          },
        }));
      } else {
        set((state) => ({
          initialInfo: {
            ...state.initialInfo,
          },
        }));
      }
    } catch (error) {
      set((state) => ({
        initialInfo: {
          ...state.initialInfo,
          openEmsByDefault: false,
        },
      }));
      return null;
    }
  },
  closePreCheckoutConfirmationDialog: () => {
    set({ preCheckoutInfo: INITIAL_STATE.preCheckoutInfo });
  },

  // ALWAYS AT THE END FOR READABILITY
  resetInfoSlice: () => {
    set({
      INITIAL_STATE,
    });
  },
});

export default createInfoSlice;
