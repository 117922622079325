import React, { useState, useCallback, useEffect, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import {
  Box,
  Icon,
  makeStyles,
  MenuItem,
  Popover,
  CircularProgress,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { has, isEmpty, isNil } from "lodash";
import {
  MFA_SHOW_ALTERNATIVES_ENABLED,
  NOTI_TYPE_MAIL,
  NOTI_TYPE_SMS,
  NOTI_TYPE_WAPP,
} from "../../../util/Constants";
import useIsMounted from "react-is-mounted-hook";

const SPACE_ITEMS = 10;

const COUNTDOWN_INITIAL = 30;
const TIMER_COUNT = COUNTDOWN_INITIAL * 1000;
const INITIAL_METHOD = NOTI_TYPE_MAIL;

const useStyles = makeStyles((theme) => ({
  dialogContentContaier: {
    display: "flex",
    flexDirection: "column",
    gap: SPACE_ITEMS,
  },
  title: {
    cursor: "move",
    padding: SPACE_ITEMS * 2,
  },
  qrImage: {
    width: 200,
    height: 200,
    marginTop: SPACE_ITEMS,
  },
  stepIndex: {
    color: "white",
    borderRadius: "100%",
    backgroundColor: theme.palette.content.mainColor,
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  step: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  mfaCodeInput: {
    marginTop: SPACE_ITEMS,
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  notAccount: {
    color: theme.palette.content.mainColor,
    textDecoration: "underline",
    textAlign: "center",
    cursor: "pointer",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  mfaAlternativesBtn: {
    textTransform: "none",
    textDecoration: "underline",
    fontStyle: "italic",
    color: theme.palette.content.mainColor,
    "&:hover": {
      textTransform: "none",
      textDecoration: "underline",
      fontStyle: "italic",
      transform: "scale(1.01)",
    },
  },
  typoMfaAlternative: {
    fontSize: "16px",
    marginLeft: 6,
    marginRight: 6,
  },
  popPaper: {
    minHeight: 35,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function MFADialog({
  open,
  handleAccept,
  handleCancel,
  mfaAlternatives,
  invokeSendMfaWhatsapp,
  invokeSendMfaSms,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [mfaCode, setMfaCode] = useState("");
  const [alternativesMenuAnchor, setAlternativesMenuAnchor] = useState(null);
  const [loadingAlternative, setLoadingAlternative] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastAlternativeSent, setLastAlternativeSent] =
    useState(INITIAL_METHOD);
  const [alternativesButtonEnabled, setAlternativesButtonEnabled] =
    useState(false);
  const [countdown, setCountdown] = useState(COUNTDOWN_INITIAL);
  const isMounted = useIsMounted();

  const handleChange = useCallback((e) => {
    setMfaCode(e.target.value);
  }, []);

  const handleOpenAlternativesMenu = (event) => {
    setAlternativesMenuAnchor(event.currentTarget);
  };
  const handleCloseAlternativesMenu = () => {
    if (!isEmpty(loadingAlternative)) {
      return;
    }
    setAlternativesMenuAnchor(null);
  };

  const handleAlternative = async (type) => {
    setLastAlternativeSent(type);
    setLoadingAlternative(type);
    setAlternativesButtonEnabled(false);
    setCountdown(COUNTDOWN_INITIAL);
    try {
      switch (type) {
        case NOTI_TYPE_WAPP:
          await invokeSendMfaWhatsapp();
          break;
        case NOTI_TYPE_SMS:
          await invokeSendMfaSms();
          break;
        default:
          break;
      }
    } catch (_) {
    } finally {
      setAlternativesMenuAnchor(null);
      setLoadingAlternative("");
    }
  };

  useEffect(() => {
    setMfaCode("");
    setAlternativesButtonEnabled(false);
    setCountdown(COUNTDOWN_INITIAL);
    setLastAlternativeSent(INITIAL_METHOD);
  }, [open]);

  useEffect(() => {
    if (open) {
      const enableTimer = setTimeout(() => {
        setAlternativesButtonEnabled(true);
      }, TIMER_COUNT);

      const countdownInterval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => {
        clearTimeout(enableTimer);
        clearInterval(countdownInterval);
      };
    }
  }, [open, lastAlternativeSent]);

  const needToShowAlternativesCombo =
    !isNil(mfaAlternatives) &&
    !isEmpty(mfaAlternatives) &&
    MFA_SHOW_ALTERNATIVES_ENABLED === true;

  const handleClickSend = async (params) => {
    setLoading(true);
    try {
      await handleAccept(params);
    } catch (_) {
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  const messageText = useMemo(() => {
    switch (lastAlternativeSent) {
      case NOTI_TYPE_MAIL:
        return t("FORGOT_PASS_DIALOG_CODE_SENT");
      case NOTI_TYPE_SMS:
        return t("MFA_DIALOG_CODE_SENT_SMS");
      case NOTI_TYPE_WAPP:
        return t("MFA_DIALOG_CODE_SENT_WHATSAPP");
      default:
        return t("FORGOT_PASS_DIALOG_CODE_SENT");
    }
  }, [lastAlternativeSent, t]);

  const identifierText = useMemo(() => {
    switch (lastAlternativeSent) {
      case NOTI_TYPE_MAIL:
        return "Mail";
      case NOTI_TYPE_SMS:
        return "SMS";
      case NOTI_TYPE_WAPP:
        return "WhatsApp";
      default:
        return "Mail";
    }
  }, [lastAlternativeSent]);

  const colorByMethod = useMemo(() => {
    switch (lastAlternativeSent) {
      case NOTI_TYPE_MAIL:
        return "#0F548C";
      case NOTI_TYPE_SMS:
        return "#7d3c98";
      case NOTI_TYPE_WAPP:
        return "#25D366";
      default:
        return "#0F548C";
    }
  }, [lastAlternativeSent]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      disableEscapeKeyDown={true}
    >
      <div className={classes.title} id="draggable-dialog-title">
        <Typography variant="h5">{t("MFA_DIALOG_TITLE")}</Typography>
      </div>

      <DialogContent className={classes.dialogContentContaier}>
        <Alert
          style={{
            backgroundColor: `${colorByMethod}22`,
            color: colorByMethod,
            fontWeight: "bold",
          }}
          icon={
            <>
              {lastAlternativeSent === NOTI_TYPE_WAPP ? (
                <Icon
                  className="fab fa-whatsapp"
                  style={{ fontSize: "2rem", color: colorByMethod }}
                />
              ) : lastAlternativeSent === NOTI_TYPE_SMS ? (
                <Icon
                  className="fas fa-sms"
                  style={{ fontSize: "2rem", color: colorByMethod }}
                />
              ) : (
                <Icon
                  className="fas fa-at"
                  style={{ fontSize: "2rem", color: colorByMethod }}
                />
              )}
            </>
          }
        >
          {messageText}
        </Alert>
        <div className={classes.step}>
          <Typography variant="h6">{t("MFA_DIALOG_STEP_TWO")}</Typography>
          <TextField
            id="mfa-code-input"
            label={t("MFA_DIALOG_INPUT_LABEL")}
            variant="outlined"
            fullWidth
            className={classes.mfaCodeInput}
            value={mfaCode}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
                handleClickSend({ mfaCode: mfaCode });
              }
            }}
            inputProps={{ maxLength: 6 }}
            autoFocus
            autoComplete="off"
            type="number"
            disabled={loading}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {needToShowAlternativesCombo ? (
          <>
            <Button
              id="mfa-alternatives-menu-btn"
              aria-controls="mfa-alternatives-menu"
              aria-haspopup="true"
              onClick={handleOpenAlternativesMenu}
              className={classes.mfaAlternativesBtn}
              disabled={!alternativesButtonEnabled}
            >
              {`¿No ha recibido el ${identifierText}? `}
              {!alternativesButtonEnabled && `(${countdown})`}
            </Button>
            <Popover
              id="mfa-alternatives-menu"
              anchorEl={alternativesMenuAnchor}
              keepMounted
              open={Boolean(alternativesMenuAnchor)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={handleCloseAlternativesMenu}
              classes={{
                paper: classes.popPaper,
              }}
            >
              {has(mfaAlternatives, NOTI_TYPE_WAPP) && (
                <MenuItem
                  onClick={() => handleAlternative(NOTI_TYPE_WAPP)}
                  disabled={!isEmpty(loadingAlternative)}
                >
                  <Icon className="fab fa-whatsapp" />
                  <Typography className={classes.typoMfaAlternative}>
                    Enviar de nuevo por WhatsApp
                  </Typography>
                  {loadingAlternative === NOTI_TYPE_WAPP && (
                    <CircularProgress size={16} />
                  )}
                </MenuItem>
              )}

              {has(mfaAlternatives, NOTI_TYPE_SMS) && (
                <MenuItem
                  onClick={() => handleAlternative(NOTI_TYPE_SMS)}
                  disabled={!isEmpty(loadingAlternative)}
                >
                  <Icon className="fas fa-sms" />
                  <Typography className={classes.typoMfaAlternative}>
                    Enviar de nuevo por SMS
                  </Typography>
                  {loadingAlternative === NOTI_TYPE_SMS && (
                    <CircularProgress size={16} />
                  )}
                </MenuItem>
              )}
            </Popover>
          </>
        ) : (
          <Tooltip title={t("MFA_DIALOG_NOT_SEND")} placement="top">
            <Button
              id="mfa-alternatives-menu-btn"
              aria-controls="mfa-alternatives-menu"
              aria-haspopup="true"
              className={classes.mfaAlternativesBtn}
            >
              {`¿No ha recibido el ${identifierText}? `}
            </Button>
          </Tooltip>
        )}

        <Box>
          <Button
            onClick={() => handleClickSend({ mfaCode })}
            color="primary"
            disabled={mfaCode.length < 6 || loading}
          >
            {loading ? (
              <CircularProgress size={16} />
            ) : (
              t("MFA_DIALOG_VALIDATE_CODE")
            )}
          </Button>

          <Button onClick={handleCancel}>{t("CANCEL")}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
