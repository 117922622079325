import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EmployeeStorePageWrapper from "../layout/EmployeeStorePageWrapper";
import { Box } from "@material-ui/core";
import ListItem from "../components/HomeListItem";
import HomeTopBar from "../components/HomeTopBar";
import HomeFilterBar from "../components/HomeFilterBar";
import HomeFooter from "../components/HomeFooter";
import useEmployeeStoreState from "../store/EmployeeStoreState";
import ProductQuickViewModal from "../components/ProductQuickViewModal";
import useProducts from "../store/hooks/useProducts";
import { Skeleton } from "@material-ui/lab";
import Paginator from "../components/Paginator";
import useFilters from "../store/hooks/useFilters";
import { isArray, isEmpty, isNil } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: 2,
    height: "100%",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    gap: 10,
    overflowY: "auto",
    alignItems: "stretch",
    padding: 10,
  },
  skeletonContainer: {
    display: "flex",
    gap: "15px",
    flexDirection: "column",
  },
  filtersContainer: {
    minWidth: 200,
    maxWidth: 300,
    // width: "20%",
    height: "fit-content",
    minHeight: "100%",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  resultsContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    height: "fit-content",
    padding: 4,
  },

  topBarContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    paddingLeft: 4,
    paddingRight: 4,
  },
}));

const SKELETON_ITEMS_SIZE = 3;
const SKELETON_ITEMS = () => {
  let result = [];
  for (let i = 0; i < SKELETON_ITEMS_SIZE; i++) {
    result.push(`skeleton-${i}`);
  }
  return result;
};

export const TOP_ITEM_ID_TO_SCROLL = "top-bar-container";

export default function EmployeeStoreHomePage() {
  const classes = useStyles();
  const { selectedProduct, openQuickView, closeQuickView } =
    useEmployeeStoreState();
  const { products, loadProducts, loadingProducts, errorProducts } =
    useProducts();
  const { availableFilters, isInitialLoad, loadingFilters } = useFilters();

  useEffect(() => {
    if (
      availableFilters.length > 0 &&
      isInitialLoad &&
      isEmpty(products) &&
      !loadingProducts
    ) {
      loadProducts();
    }
  }, [
    availableFilters,
    isInitialLoad,
    loadProducts,
    loadingProducts,
    products,
  ]);

  const loadingItems = loadingProducts || loadingFilters;

  return (
    <EmployeeStorePageWrapper>
      <div className={classes.root}>
        <Box className={classes.dataContainer}>
          <Box className={classes.filtersContainer}>
            <HomeFilterBar />
          </Box>
          <Box className={classes.resultsContainer}>
            <Box className={classes.topBarContainer} id={TOP_ITEM_ID_TO_SCROLL}>
              <HomeTopBar />
            </Box>
            {loadingItems ? (
              <div className={classes.skeletonContainer}>
                {SKELETON_ITEMS().map((x) => {
                  return (
                    <Skeleton
                      key={x}
                      variant="rect"
                      width={"100%"}
                      height={150}
                    />
                  );
                })}
              </div>
            ) : errorProducts ? (
              <p>{errorProducts}</p>
            ) : (
              <Box className={classes.resultsContainer}>
                {!isNil(products) &&
                  isArray(products) &&
                  products.map((x) => (
                    <ListItem
                      item={x}
                      key={x.ean}
                      onQuickView={openQuickView}
                    />
                  ))}
                <Paginator />
              </Box>
            )}
          </Box>
        </Box>
        <HomeFooter />
      </div>
      <ProductQuickViewModal
        product={selectedProduct}
        open={Boolean(selectedProduct)}
        onClose={closeQuickView}
      />
    </EmployeeStorePageWrapper>
  );
}
