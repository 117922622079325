import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty, isFunction, isNil, isObject, isString, size } from "lodash";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  CircularProgress,
  ClickAwayListener,
  Icon,
  ListItemIcon,
} from "@material-ui/core";
import { toast } from "react-toastify";

import * as API from "../api/api";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../core/providers/AuthContext";
import CustomBodyToast from "../../toast/CustomBodyToast";
import {
  HTTP_STATUS_UNAUTHORIZED,
  TOAST_CONTAINER_LAYOUT,
} from "../../../util/Constants";
import { INITIAL_DIALOG_CONFIRM_QUERY_ACTION } from "../table/Table";
import ConfirmQueryActionDialog from "./ConfirmQueryActionDialog";

const MIN_WIDTH = 190;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    marginLeft: 10,
  },
  buttonSelect: {
    minWidth: MIN_WIDTH,
    backgroundColor: `${theme.palette.content.mainColor}55`,
    maxHeight: 25,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}99`,
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    minWidth: MIN_WIDTH,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const commonIcon = {
  fontSize: "1rem",
  width: "auto",
  height: "auto",
  padding: 2,
  marginInline: 4,
};

export default function GlobalActionSelect({
  keyReport,
  globalActions,
  handleRefreshCurrent,
  selectedTab,
  coordinationFilters,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useContext(AuthContext);
  const REQUEST_HEADERS = useRequestHeaders();
  const theme = useTheme();
  const [executingGlobalAction, setExecutingGlobalAction] = useState(false);
  const [confirmQueryActionDialog, setConfirmQueryActionDialog] = useState(
    INITIAL_DIALOG_CONFIRM_QUERY_ACTION
  );

  const handleClickBtnTrigger = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleExecuteGlobalAction = useCallback(
    async ({ keyAction, needRefreshAfterExecute }) => {
      setExecutingGlobalAction(true);
      let toastContent = null;
      let toastConfig = {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: "handleExecuteGlobalAction",
      };
      let wasOk = false;

      const response = await API.executeQueryAction({
        REQUEST_HEADERS,
        body: {
          selectedSection: selectedTab,
          coordinationFilters,
          query: keyReport,
        },
        keyAction,
      });

      if (!isNil(response)) {
        const { status } = response;
        if (
          status === HTTP_STATUS_UNAUTHORIZED &&
          !isNil(logout) &&
          isFunction(logout)
        ) {
          logout(true);
        } else {
          const { ok, msg } = response;
          if (ok === true) {
            wasOk = true;
            toast.success(t("FORM_SUCCESS_ACTION"), toastConfig);
          } else {
            const rightMsg =
              !isNil(msg) && !isEmpty(msg)
                ? msg
                : t("ERROR_RESOURCE_NOT_FOUND_TEXT");
            toastContent = <CustomBodyToast title={rightMsg} />;
          }
        }
      } else {
        toastContent = (
          <CustomBodyToast title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")} />
        );
      }

      if (toastContent !== null) {
        toast.error(toastContent, toastConfig);
      }

      if (needRefreshAfterExecute && wasOk) {
        handleRefreshCurrent();
      }

      setTimeout(() => {
        setExecutingGlobalAction(false);
      }, 100);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleRefreshCurrent, keyReport]
  );

  if (isNil(globalActions) || isEmpty(globalActions)) {
    return null;
  }

  return (
    <div className={classes.container}>
      {size(globalActions) === 1 && !isNil(globalActions[0]) ? (
        <Button
          aria-controls={globalActions[0]?.keyAction}
          aria-haspopup="true"
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (
              globalActions[0]?.needConfirmDialog === true &&
              !isNil(globalActions[0]?.confirmDialogTitle) &&
              isString(globalActions[0]?.confirmDialogTitle) &&
              !isEmpty(globalActions[0]?.confirmDialogTitle)
            ) {
              setConfirmQueryActionDialog({
                open: true,
                title: globalActions[0]?.confirmDialogTitle,
                params: {
                  keyAction: globalActions[0]?.keyAction,
                  needRefreshAfterExecute:
                    globalActions[0]?.needRefreshAfterExecute,
                },
              });
            } else {
              handleExecuteGlobalAction({
                keyAction: globalActions[0]?.keyAction,
                needRefreshAfterExecute:
                  globalActions[0]?.needRefreshAfterExecute,
              });
            }
          }}
          className={classes.buttonSelect}
          disabled={executingGlobalAction}
        >
          {executingGlobalAction ? (
            <CircularProgress size={16} />
          ) : (
            <>
              <Icon
                className={globalActions[0]?.btnIcon}
                style={{
                  ...commonIcon,
                  color:
                    !isNil(globalActions[0]?.btnColor) &&
                    !isEmpty(globalActions[0]?.btnColor)
                      ? isObject(globalActions[0]?.btnColor)
                        ? globalActions[0]?.btnColor?.[theme.palette.type]
                        : globalActions[0]?.btnColor
                      : null,
                }}
              />
              {globalActions[0]?.btnTooltip}
            </>
          )}
        </Button>
      ) : (
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Button
            aria-controls={`${keyReport}-global-actions-trigger`}
            aria-haspopup="true"
            variant="contained"
            onClick={handleClickBtnTrigger}
            className={classes.buttonSelect}
            disabled={executingGlobalAction}
          >
            {executingGlobalAction ? (
              <CircularProgress size={16} />
            ) : (
              <>
                <Icon className="fas fa-bolt" style={commonIcon} />
                {"Global Actions"}
              </>
            )}
          </Button>
        </ClickAwayListener>
      )}

      <StyledMenu
        id={`${keyReport}-global-action`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
      >
        {globalActions.map(
          ({
            keyAction,
            btnTooltip,
            btnIcon,
            btnColor,
            needConfirmDialog,
            confirmDialogTitle,
            needRefreshAfterExecute,
          }) => {
            return (
              <MenuItem
                key={keyAction}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (
                    needConfirmDialog === true &&
                    !isNil(confirmDialogTitle) &&
                    isString(confirmDialogTitle) &&
                    !isEmpty(confirmDialogTitle)
                  ) {
                    setConfirmQueryActionDialog({
                      open: true,
                      title: confirmDialogTitle,
                      params: {
                        keyAction,
                        needRefreshAfterExecute,
                      },
                    });
                  } else {
                    handleExecuteGlobalAction({
                      keyAction,
                      needRefreshAfterExecute,
                    });
                  }
                }}
              >
                {!isNil(btnIcon) && (
                  <ListItemIcon>
                    <Icon
                      className={btnIcon}
                      style={{
                        ...commonIcon,
                        color:
                          !isNil(btnColor) && !isEmpty(btnColor)
                            ? isObject(btnColor)
                              ? btnColor?.[theme.palette.type]
                              : btnColor
                            : null,
                      }}
                    />
                  </ListItemIcon>
                )}

                <ListItemText primary={btnTooltip} />
              </MenuItem>
            );
          }
        )}
      </StyledMenu>

      <ConfirmQueryActionDialog
        confirmQueryActionDialog={confirmQueryActionDialog}
        executeQueryAction={handleExecuteGlobalAction}
        setConfirmQueryActionDialog={setConfirmQueryActionDialog}
      />
    </div>
  );
}
