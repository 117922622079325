import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Layout from "../shared/layout/Layout";
import Login from "../solutions/login/pages/Login";
import NotFound from "../components/notFound/NotFound";
import LogRouter from "../solutions/log/routes/LogRouter";
import FormCleanerMiddleware from "../components/forms/formContainer/FormCleanerMiddleware";
import LoginProfileChooser from "../solutions/login/pages/LoginProfileChooser";

const HomeRouter = lazy(() => import("../solutions/home/routes/HomeRouter"));

//All the Solution routing imported here
const ApolloRouter = lazy(() =>
  import("../solutions/apollo/routes/ApolloRouter")
);
const LabellingRouter = lazy(() =>
  import("../solutions/apollo/routes/LabellingRouter")
);
const ApolloCommonRouter = lazy(() =>
  import("../solutions/apollo/routes/ApolloCommonRouter")
);
const TicketRouter = lazy(() =>
  import("../solutions/tickets/routes/TicketsRouter")
);
const QueryRouter = lazy(() =>
  import("../solutions/queries/routes/QueryRouter")
);
const CoreGeneralRouter = lazy(() =>
  import("../solutions/core/gen/routes/CoreGeneralRouter")
);
const ToolkitRouter = lazy(() =>
  import("../solutions/core/tools/routes/ToolkitRouter")
);
const PlvRouter = lazy(() => import("../solutions/plv/routes/PlvRouter"));

const MiReservaRouter = lazy(() =>
  import("../solutions/mr/routes/MiReservaRouter")
);
const Inbox = lazy(() => import("../solutions/inbox/Inbox"));
const MercuryRouter = lazy(() =>
  import("../solutions/mercury/routes/MercuryRouter")
);
const BpmRouter = lazy(() => import("../solutions/bpm/routes/BpmRouter"));

const BerkesRouter = lazy(() =>
  import("../solutions/berkes/routes/BerkesRouter")
);

const FenixRouter = lazy(() => import("../solutions/fenix/routes/FenixRouter"));
const FenixProcessRouter = lazy(() =>
  import("../solutions/fenix/routes/FenixProcessRouter")
);

const AnalysisRouter = lazy(() =>
  import("../solutions/analysis/routes/AnalysisRouter")
);

const Search = lazy(() => import("../solutions/search/Search"));

const DocsRouter = lazy(() => import("../solutions/docs/routes/DocsRouter"));

const ChartsRouter = lazy(() =>
  import("../solutions/charts/routes/ChartsRouter")
);

const ControlsRouter = lazy(() =>
  import("../solutions/controls/routes/ControlsRouter")
);
const AvisCommonRouter = lazy(() =>
  import("../solutions/avis/routes/AvisCommonRouter")
);

const UserRouter = lazy(() => import("../solutions/user/routes/UserRouter"));

const AvisLocalRouter = lazy(() =>
  import("../solutions/avis/routes/AvisLocalRouter")
);

const AvisZoneRouter = lazy(() =>
  import("../solutions/avis/routes/AvisZoneRouter")
);

const NotificationsRouter = lazy(() =>
  import("../solutions/notifications/routes/NotificationsRouter")
);

const FridayRouter = lazy(() =>
  import("../solutions/friday/routes/FridayRouter")
);

const DropRouter = lazy(() => import("../solutions/friday/routes/DropRouter"));

const FridayCommonRouter = lazy(() =>
  import("../solutions/friday/routes/FridayCommonRouter")
);

const OnePriceRouter = lazy(() =>
  import("../solutions/oneprice/routes/OnePriceRouter")
);

const OnePriceCommonRouter = lazy(() =>
  import("../solutions/oneprice/routes/OnePriceCommonRouter")
);

const EmployeeStoreRouter = lazy(() =>
  import("../solutions/employee-store/routes/EmployeeStoreRouter")
);
const TestsRouter = lazy(() => import("../solutions/tests/routes/TestsRouter"));

const EmployeeStoreCommonRouter = lazy(() =>
  import(
    "../solutions/employee-store-backoffice/routes/EmployeeStoreCommonRouter"
  )
);

const QuickSightRouter = lazy(() =>
  import("../solutions/quicksight/routes/QuickSightRouter")
);

export const LOGIN_PROFILE_CHOOSER_ROUTE = "/login-profile-chooser";

const Router = () => {
  return (
    <Switch>
      {/* CORE Public Pages */}
      <Route exact path="/login" component={Login} />

      <Route
        exact
        path={LOGIN_PROFILE_CHOOSER_ROUTE}
        component={LoginProfileChooser}
      />

      {/* ADMIN Private Pages */}
      <PrivateRoute path="/herramientas/*" component={ToolkitRouter} />

      {/* FORM STATE CLEANER MIDDLEWARE */}
      <PrivateRoute
        exact
        path="/state/cleaner/middleware/:to"
        component={FormCleanerMiddleware}
      />

      <PrivateRoute
        exact
        path="/employee/store/*"
        component={EmployeeStoreRouter}
      />

      {/* CORE/General Private Pages */}
      <Layout>
        <HomeRouter />
        <PrivateRoute path="/core/*" component={CoreGeneralRouter} />
        <PrivateRoute path="/tickets/*" component={TicketRouter} />
        <PrivateRoute path="/consultas/*" component={QueryRouter} />
        <PrivateRoute path="/plv/*" component={PlvRouter} />
        <PrivateRoute path="/mr/" component={MiReservaRouter} />
        <PrivateRoute exact path="/inbox" component={Inbox} />
        <PrivateRoute path="/mercury/*" component={MercuryRouter} />
        <PrivateRoute path="/bpm/*" component={BpmRouter} />
        <PrivateRoute path="/berkes/*" component={BerkesRouter} />
        <PrivateRoute path="/logging/*" component={LogRouter} />
        <PrivateRoute path="/analysis/*" component={AnalysisRouter} />
        <PrivateRoute exact path="/search" component={Search} />
        <PrivateRoute exact path="/docs" component={DocsRouter} />
        <PrivateRoute exact path="/charts" component={ChartsRouter} />
        <PrivateRoute path="/controls/*" component={ControlsRouter} />
        <PrivateRoute path="/user/*" component={UserRouter} />
        <PrivateRoute path="/notifications/*" component={NotificationsRouter} />
        <PrivateRoute path="/frontend-tests/*" component={TestsRouter} />
        <PrivateRoute path="/quicksight*" component={QuickSightRouter} />

        {/** EMPLOYEE STORE BACKOFFICE */}
        <PrivateRoute
          path="/employeestore/*"
          component={EmployeeStoreCommonRouter}
        />

        {/** APOLLO */}
        <Switch>
          <PrivateRoute exact path="/apollo/tasks/*" component={ApolloRouter} />
          <PrivateRoute
            exact
            path="/apollo/labelling/*"
            component={LabellingRouter}
          />
          <PrivateRoute path="/apollo/*" component={ApolloCommonRouter} />
        </Switch>

        {/** AVIS */}
        <Switch>
          <PrivateRoute
            exact
            path="/avis/local/*"
            component={AvisLocalRouter}
          />
          <PrivateRoute exact path="/avis/zone/*" component={AvisZoneRouter} />
          <PrivateRoute path="/avis/*" component={AvisCommonRouter} />
        </Switch>

        {/** FRIDAY */}
        <Switch>
          <PrivateRoute
            exact
            path="/friday/pedidos/*"
            component={FridayRouter}
          />
          <PrivateRoute exact path="/friday/drop/*" component={DropRouter} />
          <PrivateRoute path="/friday/*" component={FridayCommonRouter} />
        </Switch>

        {/** ONE PRICE */}
        <Switch>
          <PrivateRoute
            exact
            path="/oneprice/pricing/*"
            component={OnePriceRouter}
          />
          <PrivateRoute path="/oneprice/*" component={OnePriceCommonRouter} />
        </Switch>

        {/** FENIX */}
        <Switch>
          <PrivateRoute
            exact
            path="/fenix/inspeccionmuestras/*"
            component={FenixProcessRouter}
          />
          <PrivateRoute path="/fenix/*" component={FenixRouter} />
        </Switch>
      </Layout>

      {/* ------------ Define all new solutions here -------------- */}

      {/* Fallback NOT Found page */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Router;
