import create from "zustand";
import { createApolloSlice } from "./apolloSlice";
import { createMercurySlice } from "./mercurySlice";
import { createLabellingSlice } from "./labellingSlice";
import { createAvisSlice } from "./avisSlice";
import { createAvisZoneSlice } from "./avisZoneSlice";
import { createOnePriceSlice } from "./onePriceSlice";
import { createFridaySlice } from "./fridaySlice";
import { createDropSlice } from "./dropSlice";
import { createFenixSlice } from "./fenixSlice";

export const useSolutionStore = create((...a) => ({
  ...createMercurySlice(...a),
  ...createApolloSlice(...a),
  ...createLabellingSlice(...a),
  ...createAvisSlice(...a),
  ...createAvisZoneSlice(...a),
  ...createOnePriceSlice(...a),
  ...createFridaySlice(...a),
  ...createDropSlice(...a),
  ...createFenixSlice(...a),
}));
