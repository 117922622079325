import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./override-styles.css";
import es from "react-phone-input-2/lang/es.json";
import pt from "react-phone-input-2/lang/pt.json";
import fr from "react-phone-input-2/lang/fr.json";
import cn from "react-phone-input-2/lang/cn.json";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { isEmpty, isNil, isString, size } from "lodash";
import { toast } from "react-toastify";

import {
  LOCALE_CHINESE,
  LOCALE_PORTUGUES,
  LOCALE_SPANISH,
  LOCALE_FRENCH,
  TOAST_CONTAINER_LAYOUT,
  HTTP_STATUS_UNAUTHORIZED,
  LOCALE_ENGLISH,
  LOCALE_ENGLISH_CA,
} from "../../../../util/Constants";
import * as API from "./api";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import CustomBodyToast from "../../../../components/toast/CustomBodyToast";

const MIN_SIZE_PHONE = 6;

function resolveLocalization(locale) {
  switch (locale) {
    case LOCALE_SPANISH:
      return es;
    case LOCALE_ENGLISH:
      return undefined;
    case LOCALE_ENGLISH_CA:
      return undefined;
    case LOCALE_PORTUGUES:
      return pt;
    case LOCALE_FRENCH:
      return fr;
    case LOCALE_CHINESE:
      return cn;
    default:
      return undefined;
  }
}

function getSteps() {
  return [
    "CHANGE_TELEPHONE_STEP_1",
    "CHANGE_TELEPHONE_STEP_2",
    "CHANGE_TELEPHONE_STEP_3",
    "CHANGE_TELEPHONE_STEP_4",
  ];
}

function getStepBtnText(step) {
  switch (step) {
    case 0:
      return "NEXT";
    case 1:
      return "CHANGE_TELEPHONE_BTN_STEP_2";
    case 2:
      return "CHANGE_TELEPHONE_BTN_STEP_3";
    default:
      return "NEXT";
  }
}

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  marginRight: 5,
  fontSize: "1.2rem",
};

const useStyles = makeStyles((theme) => ({
  dialogTitleContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.1rem",
  },
  passNotMatch: {
    color: theme.palette.content.niceRed,
    fontStyle: "italic",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  dialogContentLoading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogInput: {
    marginBottom: 10,
    width: 350,
  },
  boxContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    gap: 10,
  },
  textFieldPHone: {
    maxHeight: 30,
    width: "100%",
  },
  actualPhoneNumberContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    borderRadius: "10px",
    backgroundColor: `${theme.palette.content.mainColor}66`,
    padding: "10px",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "move",
  },
  bothContainer: {
    display: "flex",
    gap: "5px",
    flexDirection: "row",
    alignItems: "end",
  },
  stepper: {
    padding: "12px 4px",
  },
  otpInputStyle: {
    width: "35px !important",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    height: "3rem",
    margin: "0 10px",
    fontSize: "2rem",
    borderRadius: "4px",
  },
  resultContainer: {
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  emptyActualPhoneBtn: {
    textTransform: "none",
    backgroundColor: `${theme.palette.content.mainColor}AA`,
    color: "#FFF",
    fontWeight: "bold",
    borderRadius: 20,
    padding: 20,
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}BB`,
      transform: "scale(1.03)",
    },
  },
  stepOneContainer: {},
}));

function DraggablePaperComponent(props) {
  return (
    <Draggable handle="#change-phone-number-dialog-title">
      <Paper {...props} />
    </Draggable>
  );
}

export default function ChangeTelephoneNumberDialog({
  open,
  handleClose,
  addDirectly,
  isOpenDirectly,
}) {
  const muiTheme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [country, setCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(false);
  const [actualPhoneNumber, setActualPhoneNumber] = useState("");
  const [isEditing, setIsEditing] = useState(addDirectly === true);
  const [otpCode, setOtpCode] = useState("");
  const [processCompleted, setProcessCompleted] = useState(false);
  const [changePhoneAccepted, setChangePhoneAccepted] = useState(false);
  const [showErrorChangePhoneAccepted, setShowErrorChangePhoneAccepted] =
    useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const inputRef = useRef();
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      let toastContent = null;
      try {
        setIsLoadingInitial(true);
        const response = await API.getUserPrivateInfo({ REQUEST_HEADERS });
        if (!isNil(response)) {
          const { status, ok, errorMsg, dataResponse } = response;
          if (status === HTTP_STATUS_UNAUTHORIZED) {
            logout(true);
          } else {
            if (!ok) {
              toastContent = <CustomBodyToast title={errorMsg} msg={null} />;
            } else {
              const phoneNumber = dataResponse?.phoneNumber;
              if (
                !isNil(phoneNumber) &&
                isString(phoneNumber) &&
                !isEmpty(phoneNumber)
              ) {
                setActualPhoneNumber(`+${phoneNumber}`);
              }
            }
          }
        } else {
          toastContent = (
            <CustomBodyToast
              title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
              msg={null}
            />
          );
        }
      } catch (error) {
      } finally {
        if (toastContent && isOpenDirectly !== true) {
          toast.error(toastContent, {
            containerId: TOAST_CONTAINER_LAYOUT,
            position: "top-right",
          });
        }
        setIsLoadingInitial(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledSave =
    isNil(phone) ||
    isNil(country) ||
    (!isNil(phone) && size(phone) < MIN_SIZE_PHONE);

  const sharedStyles = useMemo(() => {
    return {
      backgroundColor: muiTheme.palette.background.paper,
      color: muiTheme.palette.text.primary,
      "&:hover": {
        backgroundColor: muiTheme.palette.background.paper,
        color: muiTheme.palette.text.primary,
      },
    };
  }, [muiTheme]);

  const handleSaveNumber = useCallback(async () => {
    setIsLoading(true);
    let toastContent = null;

    const phoneNumber = `${country?.dialCode}${phone}`;
    const response = await API.saveNumber({
      phoneNumber,
      otpCode,
      REQUEST_HEADERS,
    });
    if (!isNil(response)) {
      const { status, ok, errorMsg } = response;
      if (status === HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else {
        if (!ok) {
          toastContent = <CustomBodyToast title={errorMsg} msg={null} />;
        } else {
          setProcessCompleted(true);
        }
      }
    } else {
      toastContent = (
        <CustomBodyToast
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          msg={null}
        />
      );
    }

    if (toastContent) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
      });
    }
  }, [country, phone, otpCode, REQUEST_HEADERS, logout, t]);

  const handleGenerateCode = useCallback(async () => {
    setIsLoading(true);
    let toastContent = null;

    const phoneNumber = `${country?.dialCode}${phone}`;
    const response = await API.getGenerateCode({
      phoneNumber,
      REQUEST_HEADERS,
    });
    if (!isNil(response)) {
      const { status, ok, errorMsg } = response;
      if (status === HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else {
        if (!ok) {
          toastContent = <CustomBodyToast title={errorMsg} msg={null} />;
        }
      }
    } else {
      toastContent = (
        <CustomBodyToast
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          msg={null}
        />
      );
    }

    if (toastContent) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
      });
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [country, phone, REQUEST_HEADERS, logout, t]);

  const handleMoveForwardClick = useCallback(async () => {
    if (activeStep === 0) {
      setShowErrorChangePhoneAccepted(false);
      setChangePhoneAccepted(false);
    }

    if (activeStep === 1) {
      if (!changePhoneAccepted) {
        setShowErrorChangePhoneAccepted(true);
        return;
      }
      await handleGenerateCode();
    }

    if (activeStep === 2) {
      await handleSaveNumber();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, changePhoneAccepted, handleGenerateCode, handleSaveNumber]);

  const handleMoveBackClick = useCallback(() => {
    if (activeStep === 0) {
      handleClose(false);
    } else if (activeStep === 3) {
      handleClose(processCompleted === true);
    } else {
      if (activeStep === 2) {
        setShowErrorChangePhoneAccepted(false);
        setChangePhoneAccepted(false);
      }
      setIsLoading(false);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  }, [activeStep, handleClose, processCompleted]);

  const phoneNumberToDisplay = useMemo(() => {
    if (
      !isNil(country) &&
      !isNil(phone) &&
      isString(phone) &&
      !isEmpty(phone)
    ) {
      return `+ (${country?.dialCode}) ${phone}`;
    } else {
      return "-";
    }
  }, [phone, country]);

  const handleChangeChangePhoneAccepted = (event) => {
    const newState = event.target.checked;
    setChangePhoneAccepted(newState);
    if (newState === true) {
      setShowErrorChangePhoneAccepted(false);
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="change-phone-number-dialog"
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          width: 450,
          height: !isEditing ? 250 : 500,
        },
      }}
      PaperComponent={DraggablePaperComponent}
      style={{ zIndex: 99999999 }}
    >
      <DialogTitle
        id="change-phone-number-dialog-title"
        style={{ cursor: "move" }}
      >
        <div className={classes.dialogTitle}>
          <div className={classes.dialogTitleContent}>
            <Icon
              className="fas fa-phone-square-alt"
              style={{ ...commonIconStyles }}
            />
            {t("CHANGE_TELEPHONE_TITLE")}
          </div>
          {!isEditing && !isLoadingInitial && (
            <IconButton onClick={() => setIsEditing(true)}>
              <Icon
                className={
                  isNil(actualPhoneNumber) || isEmpty(actualPhoneNumber)
                    ? "fas fa-plus"
                    : "fas fa-pen"
                }
                style={commonIconStyles}
              />
            </IconButton>
          )}
        </div>
      </DialogTitle>

      {isLoadingInitial ? (
        <DialogContent className={classes.dialogContentLoading}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent className={classes.dialogContent}>
          {!isEditing &&
            !isNil(actualPhoneNumber) &&
            !isEmpty(actualPhoneNumber) && (
              <div className={classes.actualPhoneNumberContainer}>
                <Typography variant="h6">{`Actual ${actualPhoneNumber}`}</Typography>
              </div>
            )}

          {!isEditing &&
            (isNil(actualPhoneNumber) || isEmpty(actualPhoneNumber)) && (
              <Button
                className={classes.emptyActualPhoneBtn}
                onClick={() => setIsEditing(true)}
              >
                <Typography variant="h6">
                  {t("CHANGE_TELEPHONE_EMPTY")}
                </Typography>
              </Button>
            )}

          {isEditing && (
            <>
              <Box className={classes.boxContainer}>
                {isLoadingInitial ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Stepper
                      activeStep={activeStep}
                      orientation="vertical"
                      className={classes.stepper}
                    >
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel>{t(label)}</StepLabel>
                          <StepContent>
                            {index === 0 ? (
                              <div className={classes.bothContainer}>
                                <PhoneInput
                                  localization={resolveLocalization(
                                    i18n?.language
                                  )}
                                  value={inputValue}
                                  onChange={(phone, country) => {
                                    setInputValue(phone);
                                    setCountry(country);
                                    if (inputRef != null) {
                                      inputRef.current.focus();
                                    }
                                  }}
                                  autoFormat={false}
                                  showDropdown={true}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  inputStyle={{ ...sharedStyles, width: 100 }}
                                  dropdownStyle={sharedStyles}
                                  searchStyle={sharedStyles}
                                  containerStyle={{
                                    ...sharedStyles,
                                    width: 100,
                                  }}
                                  buttonStyle={sharedStyles}
                                  placeholder=""
                                  searchPlaceholder={t(
                                    "GEN_DASH_FILTER_TF_LABEL"
                                  )}
                                />
                                <TextField
                                  inputRef={inputRef}
                                  id="phone-number-basic"
                                  variant="standard"
                                  className={classes.textFieldPHone}
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  type="tel"
                                />
                              </div>
                            ) : index === 1 ? (
                              <Box className={classes.stepOneContainer}>
                                <div
                                  className={classes.actualPhoneNumberContainer}
                                >
                                  <Typography variant="h6">
                                    {phoneNumberToDisplay}
                                  </Typography>
                                </div>
                                <Box mb={2} mt={2} display="flex">
                                  <Checkbox
                                    checked={changePhoneAccepted}
                                    onChange={handleChangeChangePhoneAccepted}
                                    style={{
                                      color:
                                        showErrorChangePhoneAccepted && "red",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    color={
                                      showErrorChangePhoneAccepted
                                        ? "error"
                                        : undefined
                                    }
                                  >
                                    {t("CHANGE_TELEPHONE_CHECKBOX_TEXT")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : index === 2 ? (
                              <OtpInput
                                value={otpCode}
                                onChange={setOtpCode}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                                shouldAutoFocus={true}
                                inputStyle={classes.otpInputStyle}
                              />
                            ) : index === 3 ? (
                              <div className={classes.resultContainer}>
                                <Icon
                                  className={
                                    processCompleted
                                      ? "fas fa-check-circle"
                                      : "fas fa-exclamation-circle"
                                  }
                                  style={{
                                    ...commonIconStyles,
                                    fontSize: "2rem",
                                    color: processCompleted
                                      ? muiTheme.palette.semaphore.green
                                      : muiTheme.palette.semaphore.red,
                                  }}
                                />
                                {processCompleted ? (
                                  <Typography variant="h6">
                                    {t("CHANGE_TELEPHONE_STEP_4_OK")}
                                  </Typography>
                                ) : (
                                  <Typography variant="h6">
                                    {t("CHANGE_TELEPHONE_STEP_4_ERROR")}
                                  </Typography>
                                )}
                              </div>
                            ) : (
                              <div />
                            )}
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  </>
                )}
              </Box>
            </>
          )}
        </DialogContent>
      )}
      <DialogActions>
        {isEditing && activeStep < 3 && (
          <Button
            disabled={disabledSave || isLoading}
            onClick={() => handleMoveForwardClick()}
            color="primary"
          >
            {!isLoading ? (
              <Typography>{t(getStepBtnText(activeStep))}</Typography>
            ) : (
              <CircularProgress size={18} />
            )}
          </Button>
        )}

        {isEditing && !processCompleted ? (
          <Button onClick={handleMoveBackClick}>{t("FORM_BACK")}</Button>
        ) : (
          <Button
            onClick={() => {
              if (isOpenDirectly) {
                handleClose();
              } else {
                handleMoveBackClick();
              }
            }}
          >
            {t("CLOSE")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
