import React, { useContext } from "react";
import {
  commonIconStyles,
  useStyles,
} from "../styles/LoginProfileChooserStyles";
import Grid from "@material-ui/core/Grid";
import { Box, Icon, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { AuthContext } from "../../../core/providers/AuthContext";
import { LayoutContext } from "../../../core/providers/LayoutContext";
import { HOME_TAB } from "../../../util/Constants";
import {
  EMPLOYEE_STORE_HOME_ROUTE,
  EMPLOYEE_STORE_PREFIX_ROUTE,
} from "../../employee-store/routes/EmployeeStoreRouter";

export default function LoginProfileChooser() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { setNewAuthState } = useContext(AuthContext);
  const { changeActiveTab } = useContext(LayoutContext);

  const hasProfiles = location?.state?.response?.hasProfiles;
  const profiles = location?.state?.response?.profiles;

  if (!hasProfiles || isNil(profiles) || isEmpty(profiles)) {
    return <p>Invalid profiles</p>;
  }

  function handleChooseProfile({ profile, openEmsByDefault }) {
    const newAuthToSet = {
      remember: location?.state?.remember === true ? true : false,
      response: { ...profile?.loginResult },
      username: profile?.userId,
      openEmsByDefault,
    };

    setNewAuthState(newAuthToSet);
    changeActiveTab(HOME_TAB);
    if (
      openEmsByDefault === true &&
      newAuthToSet?.response?.emsEnabled === true
    ) {
      history.push(
        `${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_HOME_ROUTE}`
      );
    } else {
      history.push("/");
    }
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.contentBox}>
        <Box className={classes.titleBox}>
          <Icon
            className="fas fa-users"
            style={commonIconStyles}
            fontSize="large"
          />
          <Typography variant="h3" className={classes.title}>
            Please choose one of your profiles to continue using the platform
          </Typography>
        </Box>

        <Grid container spacing={2} className={classes.gridContainer}>
          {profiles.map((profile, index) => {
            const openEmsByDefault =
              profile?.loginResult?.openEmsByDefault === true ? true : false;
            return (
              <Grid
                item
                key={profile.userId}
                xs={12}
                sm={6}
                md={4}
                lg={6}
                className={classes.gridItem}
                onClick={() => {
                  handleChooseProfile({ profile, openEmsByDefault });
                }}
              >
                <Box className={classes.profileCard}>
                  <Box className={classes.infoBox}>
                    <Icon
                      className="fas fa-signature"
                      style={commonIconStyles}
                      fontSize="medium"
                    />
                    <Typography variant="h4">{profile.profileName}</Typography>
                  </Box>
                  {/* <Box className={classes.infoBox}>
                  <Icon
                    className="fas fa-tag"
                    style={commonIconStyles}
                    fontSize="small"
                  />
                  <Typography variant="h6">{profile.profileCode}</Typography>
                </Box> */}
                  <Box className={classes.infoBox}>
                    <Icon
                      className="fas fa-user-tag"
                      style={commonIconStyles}
                      fontSize="small"
                    />
                    <Typography variant="subtitle1">
                      {profile.userId}
                    </Typography>
                  </Box>
                  <Typography variant="h6" className={classes.descriptionText}>
                    {profile.profileDesc}
                  </Typography>
                  <Typography
                    variant="h6"
                    className={classes.profileIndexText}
                  >{`#${index + 1}`}</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}
