import { useEffect, useState } from "react";

const useSingleTab = () => {
  const [isDuplicateTab, setIsDuplicateTab] = useState(false);

  useEffect(() => {
    const checkIfPrimaryTab = () => {
      const existingTab = localStorage.getItem("app-tab-active");

      if (existingTab) {
        setIsDuplicateTab(true);
      } else {
        localStorage.setItem("app-tab-active", "true");
      }
    };

    checkIfPrimaryTab();

    const handleStorageChange = (event) => {
      if (event.key === "app-tab-active" && event.newValue === null) {
        window.location.reload();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    const handleBeforeUnload = () => {
      localStorage.removeItem("app-tab-active");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      localStorage.removeItem("app-tab-active");
    };
  }, []);

  return isDuplicateTab;
};

export default useSingleTab;
