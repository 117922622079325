import { METHOD_GET } from "../../../util/Constants";
import { BASE_EMS_URL, handleApiResponse } from "./base";

const SIMULATION_START_ENDPOINT = "/simulation/start";
const SIMULATION_TEST_SPLIT_ENDPOINT = "/simulation/test-split";
const SIMULATION_GET_STATUS_ENDPOINT = "/simulation/get-status";

export const startSimulationRequestApi = async ({ headers, logout, body, emsIdCompra }) => {
  const response = await fetch(`${BASE_EMS_URL}${SIMULATION_START_ENDPOINT}?emsIdCompra=${emsIdCompra}`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

  return handleApiResponse(response, logout);
};

export const testSimulationSplitRequestApi = async ({
  headers,
  logout,
  body,
}) => {
  const response = await fetch(
    `${BASE_EMS_URL}${SIMULATION_TEST_SPLIT_ENDPOINT}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    }
  );

  return handleApiResponse(response, logout);
};

export const getStatusSimulationRequestApi = async ({
  headers,
  logout,
  simulationId,
}) => {
  const response = await fetch(
    `${BASE_EMS_URL}${SIMULATION_GET_STATUS_ENDPOINT}?simulationId=${simulationId}`,
    {
      method: METHOD_GET,
      headers: headers,
    }
  );

  return handleApiResponse(response, logout);
};
