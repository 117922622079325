import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import useSingleTab from "../../store/hooks/useSingleTab";
import EmployeeStoreLogo from "../header/components/EmployeeStoreLogo";

const GLASS = {
  background: "rgba(255, 255, 255, 0.2)",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(4px)",
  WebkitBackdropFilter: "blur(4px)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: "32px",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
  dialogBackdrop: {
    ...GLASS,
  },
}));

export default function EmployeeStoreLayoutUseSingleTabDialog() {
  const { t } = useTranslation();
  const classes = useStyles();
  const isDuplicateTab = useSingleTab();

  return (
    <Dialog
      open={isDuplicateTab}
      maxWidth="lg"
      classes={{
        container: classes.dialogBackdrop,
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <EmployeeStoreLogo />
        <DialogContentText variant="h5">
          {t("EMPLOYEE_STORE.CAN_BE_USED_ONLY_IN_ONE_TAB", {
            app: t("EMPLOYEE_STORE.DISPLAY_NAME"),
          })}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
