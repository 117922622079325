import { useCallback, useMemo } from "react";
import useEmployeeStoreState from "../EmployeeStoreState";
import { isEmpty, isNil } from "lodash";

const useSimulation = () => {
  const {
    parentSimulationId,
    simulationsIds,
    responseSimulations,
    isSimulating,
    handleSimulation,
    showSimulationDetails,
    toggleSimulationDetails,
    showErrorDialogSimulating,
    openShowErrorDialogSimulation,
    closeShowErrorDialogSimulation,
    resetSimulation,
  } = useEmployeeStoreState((state) => ({
    responseSimulations: state.responseSimulations,
    parentSimulationId: state.parentSimulationId,
    simulationsIds: state.simulationsIds,
    isSimulating: state.isSimulating,
    handleSimulation: state.handleSimulation,
    showSimulationDetails: state.showSimulationDetails,
    toggleSimulationDetails: state.toggleSimulationDetails,
    showErrorDialogSimulating: state.showErrorDialogSimulating,
    openShowErrorDialogSimulation: state.openShowErrorDialogSimulation,
    closeShowErrorDialogSimulation: state.closeShowErrorDialogSimulation,
    resetSimulation: state.resetSimulation,
  }));

  const getProductSimulationInfo = useCallback(
    ({ sku, ean }) => {
      if (
        isNil(sku) ||
        isNil(ean) ||
        isNil(responseSimulations) ||
        isEmpty(responseSimulations)
      ) {
        return null;
      }

      for (const [simulationId, products] of responseSimulations.entries()) {
        for (const product of products) {
          if (
            !isNil(product) &&
            product.responseSkuNumber === sku &&
            product.responseEanNumber === ean
          ) {
            return { ...product, simulationId, sameSKU: true };
          } else if (
            !isNil(product) &&
            product.responseSkuNumber !== sku &&
            product.responseEanNumber === ean
          ) {
            return {
              ...product,
              simulationId,
              sameSKU: false,
            };
          }
        }
      }

      return null;
    },
    [responseSimulations]
  );

  const simulationTotalizers = useMemo(() => {
    if (isNil(responseSimulations) || isEmpty(responseSimulations)) {
      return null;
    }

    let totalPriceProducts = 0;
    let totalDiscounts = 0;
    let totalStructuralDiscount = 0;
    let totalPromoDiscount = 0;
    let totalManualDiscount = 0;
    let totalTaxes = 0;
    let totalCA = 0;
    let totalNF = 0;
    let totalItems = 0;

    for (const [simulationId, products] of responseSimulations.entries()) {
      for (const product of products) {
        if (!isNil(product) && !isNil(simulationId)) {
          totalPriceProducts += product.responseItemPrice;
          totalDiscounts += product.responseTotalDesc;
          totalStructuralDiscount += product.responseDescStructural;
          totalPromoDiscount += product.responseDescPromo;
          totalManualDiscount += product.responseDescManual;
          totalTaxes += product.responseIva;
          totalCA += product.responseTotalCaSimulation;
          totalNF += product.responseGrossValue;
          totalItems += product.responseQtySalesOrder;
        }
      }
    }

    return {
      totalPriceProducts,
      totalDiscounts,
      totalStructuralDiscount,
      totalPromoDiscount,
      totalManualDiscount,
      totalTaxes,
      totalCA,
      totalNF,
      totalItems,
    };
  }, [responseSimulations]);

  return {
    parentSimulationId,
    simulationsIds,
    getProductSimulationInfo,
    simulationTotalizers,
    isSimulating,
    handleSimulation,
    showSimulationDetails,
    toggleSimulationDetails,
    showErrorDialogSimulating,
    openShowErrorDialogSimulation,
    closeShowErrorDialogSimulation,
    resetSimulation,
  };
};

export default useSimulation;
