import React from "react";
import { isNil, isString, size } from "lodash";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  text: {
    marginInline: 4,
    fontStyle: "italic",
    fontSize: 13,
    fontWeight: "bold",
  },
}));

export default function EndTextBadge({ schema }) {
  const classes = useStyles();

  const endTextBadge =
    !isNil(schema?.endTextBadge) &&
    isString(schema?.endTextBadge) &&
    size(schema?.endTextBadge) > 0
      ? schema?.endTextBadge
      : null;

  if (isNil(endTextBadge)) {
    return null;
  }

  return <Typography className={classes.text}>{endTextBadge}</Typography>;
}
